/*
 * Copyright © MEFTEK LLC. All rights reserved.
 * This information is confidential and proprietary to MEFTEK LLC
 * and may not be used, modified, copied or distributed.
 */

export interface Version {
  date: string;
  model: number;
  number: string;
}

export const initialVersion: Version = {
  date: '000000',
  model: 0,
  number: '0.0'
};
