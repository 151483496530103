/*
 * Copyright © MEFTEK LLC. All rights reserved.
 * This information is confidential and proprietary to MEFTEK LLC
 * and may not be used, modified, copied or distributed.
 */

import {StorageService} from "./services/storage.service";

export const   saveFilter = (
  storageService: StorageService,
  filterKey: string,
  $event: any
): boolean => {
  let columnFiltersActive = false;
  (Object.keys($event.filters) as (keyof typeof $event.filters)[]).forEach((key) => {
    if ($event.filters[key].length > 1 || $event.filters[key][0]?.value != null) {
      columnFiltersActive = true;
    }
  });

  storageService.setItem(filterKey, $event.filters);

  return columnFiltersActive;
}

export const saveSort = (
  storageService: StorageService,
  defaultSortField: string,
  defaultSortOrder: number,
  sortFieldKey: string,
  sortOrderKey: string,
  $event: any
): boolean => {
  let sortActive = !($event.field === defaultSortField && $event.order === defaultSortOrder);
  storageService.setItem(sortFieldKey, $event.field);
  storageService.setItem(sortOrderKey, $event.order);
  return sortActive;
}
