<div class="flex justify-content-between flex-wrap">
  <div class="flex justify-content-right text-sm">
    <legend *ngIf="!hideLegend">
      <span>Legend:&nbsp;</span>
      <span class="legend-active">Active</span>:
      <span class="legend-soon">Soon</span>:
      <span class="legend-retired">Retired</span>:
      <span class="legend-hidden">Hidden</span>
    </legend>
  </div>
  <div class="flex justify-content-right text-sm">
    v{{ appVersion }}{{ dbEnv }}-{{ stageVersion.number }}S [{{ stageVersion.date }}]
    -{{ prodVersion.number }}P [{{ prodVersion.date }}]
    <span *ngIf="authService.userData as user" class="ml-1">{{ user.email }}</span>
  </div>
</div>
