import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Ad} from "../ad.model";
import {Observable} from "rxjs";

@Component({
  selector: 'app-ad-edit',
  templateUrl: './ad-edit.component.html',
  styleUrls: ['./ad-edit.component.scss']
})
export class AdEditComponent implements OnInit {

  @Input() ad: Ad;

  private _visible: boolean = false;
  @Input()
  get visible(): boolean {
    return this._visible;
  }
  set visible(value: boolean) {
    this._visible = value;
    this.visibleChange.emit(this._visible);
  }
  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() submitEvent = new EventEmitter<Ad>();

  @ViewChild('adForm') adForm: any;

  constructor(
    private readonly elementRef:ElementRef,
  ) { }

  ngOnInit(): void {
    console.log("ngOnInit");
    // this.currentAd = {...this.ad};
    this.currentAd = {
      campaign: {
        end: '',
        start: '',
      },
      coordinates: {
        lat: 0,
        lng: 0,
      },
      documentId: "",
      id: 0,
      name: "",
      radius: 0,
      statistics: {
        clicked: 0,
        displayed: 0,
      }
    };
  }

  currentAd: Ad;
  // get isDirty(): boolean {
  //   return true;
  // }

  adUrl: Observable<any>;

  onShow() {
    this.currentAd = {...this.ad};
  }

  handleSubmit() {
    this.submitEvent.emit(this.currentAd);
    this.visible = false;
  }

  onChangeName($event: any) {

  }

  handleCancel() {
    this.visible = false;
  }
}
