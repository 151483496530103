import {Injectable} from '@angular/core';
import {DatePipe} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class CsvExportService {

  constructor(private readonly datePipe: DatePipe) { }

  async exportCsvFile(rows: any[], csvColumns: any[], filename: string) {
    const dateString = this.datePipe.transform(Date.now(), "yyyy-MM-dd HH:mm:ss")!;

    let headers: any[] = [];
    let csvArr = [];
    let csvRow = {};

    for (let i = 0; i < rows.length; i++) {
      csvRow = {};
      csvColumns.forEach((col) => {
        if (i === 0) {
          headers.push(col.header);
        }
        let x: any[] = [];
        switch (col.field) {
          case 'android':
          case 'emailVerified':
          case 'iOS':
          case 'web':
            x.push(rows[i][col.field] === 'Y' ? 'Yes' : 'No');
            break;
          default:
            x.push(rows[i][col.field]);
            break;
        }
        // @ts-ignore
        csvRow[col.header] = x.join('\n');//rows[i][col.fields[0]];
      });

      csvArr.push(csvRow);
    }

    const fullFilename = `${filename} - ${dateString}`;
    this.saveCsvFile(csvArr, headers, fullFilename, dateString);
  }

  async exportMapCsvFiles(rows: any[], csvColumns: any[]) {
    const showValues = 'ASOT';
    const sortedRows = rows.filter(row => {
      return showValues.indexOf(row.tagStatus) !== -1 || showValues.indexOf(row.cheerStatus) !== -1
    }).sort(function (a, b) {
      return a.state.localeCompare(b.state) || a.name.localeCompare(b.name);
    });
    const dateString = this.datePipe.transform(Date.now(), "yyyy-MM-dd HH:mm:ss")!;

    let stateString = '';
    let stateCount = 0;
    let headers: any[] = [];
    let csvArr = [];
    let csvRow = {};

    for (let i = 0; i < sortedRows.length; i++) {
      if (stateString !== sortedRows[i]['state']) {
        stateCount += 1;
        if (stateCount > 9) {
          stateCount = 0;
          await this.pause(1000);
        }
        const fileName = `TagaBrew Map List - ${dateString} (${stateString})`;
        this.saveCsvFile(csvArr, headers, fileName, dateString);
        stateString = sortedRows[i]['state'];
        csvArr = [];
        csvRow = {};
      }
      csvRow = {};
      csvColumns.forEach((col) => {
        if (i === 0) {
          headers.push(col.header);
        }
        let x: any[] = [];
        let value = '';
        let tagStatus = '';
        let cheerStatus = '';
        switch (col.field) {
          case 'coordinates':
            const u = sortedRows[i][col.field].split(',');
            x.push(`${u.join(' ')}`);
            break;
          case 'phone':
            value = sortedRows[i][col.field];
            if (value?.length > 0 && value[0] !== '(') {
              const city = value.slice(0, 3);
              let number = value.slice(3);
              number = number.slice(0, 3) + '-' + number.slice(3);
              value = (" (" + city + ") " + number).trim()
            }
            x.push(value);
            break
          case 'dog':
          case 'recycle':
            x.push(sortedRows[i][col.field] === 'Y' ? 'Yes' : 'No');
            break;
          case 'tagStatus':
          case 'cheerStatus':
            value = sortedRows[i][col.field];
            if (value === 'A') {
              value = 'Active';
            } else if (value === 'S') {
              value = 'Soon';
            } else if (value === 'T') {
              value = 'Temporarily Closed (No TagaBrew Out)';
            } else if (value === 'O') {
              value = 'Out of Stock (No TagaBrew Out)';
            } else {
              value = 'Not Available';
            }
            x.push(value);
            break;
          case 'category':
            tagStatus = sortedRows[i].tagStatus;
            cheerStatus = sortedRows[i].cheerStatus;
            // TS, TA, TO, TC, CS, CA, CO, CC
            if ('AOST'.includes(tagStatus)) {
              value = `TagaBrew (${this.expandStatus(tagStatus)})`;
            } else if ('AOST'.includes(cheerStatus)) {
              value = `TagaCheer (${this.expandStatus(cheerStatus)})`;
            } else {
              value = this.expandStatus('N/A');
            }
            x.push(value);
            break
          default:
            x.push(sortedRows[i][col.field]);
            break;
        }
        // @ts-ignore
        csvRow[col.header] = x.join('\n');//rows[i][col.fields[0]];
      });

      csvArr.push(csvRow);
    }
    const fileName = `TagaBrew Map List - ${dateString} (${stateString})`;
    this.saveCsvFile(csvArr, headers, fileName, dateString);
  }

  private saveCsvFile(csvArr: any[], headers: any[], filename: string, dateString: string) {
    if (csvArr?.length > 0) {
      const replacer = (_key: any, value: any) => value === null ? '' : value; // specify how you want to handle null values here
      // @ts-ignore
      let csv = csvArr.map(row => headers.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
      csv.unshift(headers.join(','));
      let csvData = csv.join('\n');
      const CSV_TEXT = 'text/csv';
      const CSV_EXT = '.csv';
      const data: Blob = new Blob([csvData], {type: CSV_TEXT});
      const fullFileName = `${filename}${CSV_EXT}`;
      const FileSaver = require('file-saver');
      FileSaver.saveAs(data, fullFileName);
    }
  }

  private expandStatus(status: string) : string {
    let expanded = 'N/A';
    switch(status) {
      case 'A':
        expanded = 'Active';
        break;
      case '0':
        expanded = 'Out of Stock';
        break;
      case 'S':
        expanded = 'Soon';
        break;
      case 'T':
        expanded = 'Temporarily Closed';
        break;
    }
    return expanded;
  }

  private pause(ms: number) {
    return new Promise(
      (resolve, _reject) => {
        setTimeout(resolve, ms || 1000);
      }
    );
  }

}
