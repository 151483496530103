import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Observable} from "rxjs";

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent {

  @Input() logoUrl: Observable<any>;
  @Output() imageDataUrlChange = new EventEmitter<string>();

  imageDataUrl: string | ArrayBuffer; // Variable to store image data URL

  compressImage(src: string, newX: number, newY: number) {
    return new Promise((res, rej) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = newX;
        canvas.height = newY;
        const ctx = canvas.getContext('2d');
        ctx?.drawImage(img, 0, 0, newX, newY);
        const dataURL = canvas.toDataURL('image/png');
        res(dataURL);
      }
      img.onerror = error => rej(error);
    })
  }

  handleUpload(event: any): void {
    const file = event.files[0]; // Get the first file from the event

    if (file) {
      // Check if the file is an image
      if (file.type.startsWith('image/')) {
        // Create a FileReader to read the file content
        const reader = new FileReader();

        // Define what to do when the file is read
        reader.onload = (e: any) => {
          // e.target.result contains the image data URL
          // this.imageDataUrl = e.target.result;
          // console.log('Image Data URL:', this.imageDataUrl);

          this.compressImage(e.target.result, 100, 100).then(compressed => {
            this.imageDataUrl = compressed as string;
            this.imageDataUrlChange.emit(this.imageDataUrl);
          })

          // // Optional: create an image element to preview the image
          // const img = new Image();
          // img.src = this.imageDataUrl as string;
          // document.body.appendChild(img);
        };

        // Define what to do in case of error
        reader.onerror = (error) => {
          console.error('Error reading file:', error);
        };

        // Read the file as a Data URL
        reader.readAsDataURL(file);
      } else {
        console.error('Selected file is not an image.');
      }
    }
  }
}

export class LogoNameGenerator {
  private static basePath = 'logo/';
  private static extension = '.png';

  static generateNewFilename(baseNumber: number, currentFilename?: string): string {
    // Format the base number with leading zeroes to be 4 digits
    const formattedBaseNumber = baseNumber.toString().padStart(4, '0');

    if (!currentFilename) {
      // If no filename is provided, generate the default filename with the base number
      return `${this.basePath}logo${formattedBaseNumber}${this.extension}`;
    }

    // Extract parts of the filename
    const regex = new RegExp(`^(logo${formattedBaseNumber})(?:_(\\d+))?(${this.extension})$`);
    const match = currentFilename.match(regex);

    if (match) {
      const baseName = match[1];
      const suffix = match[2] ? parseInt(match[2], 10) + 1 : 1;
      const newSuffix = suffix.toString().padStart(2, '0'); // Ensure suffix is 2 digits
      return `${this.basePath}${baseName}_${newSuffix}${this.extension}`;
    }

    // If the filename format is unexpected, return an empty string or a default value
    // Adjust this return value based on your use case
    return `${this.basePath}logo${formattedBaseNumber}_01${this.extension}`;
  }

// // Example usage:
// console.log(FileNameGenerator.generateNewFilename(1, '')); // Outputs: logo/logo0001.png
// console.log(FileNameGenerator.generateNewFilename(1, 'logo/logo0001.png')); // Outputs: logo/logo0001_01.png
// console.log(FileNameGenerator.generateNewFilename(1, 'logo/logo0001_01.png')); // Outputs: logo/logo0001_02.png
// console.log(FileNameGenerator.generateNewFilename(99, 'logo/logo0099_99.png')); // Outputs: logo/logo0099_100.png
// console.log(FileNameGenerator.generateNewFilename(1, 'unexpected/format.png')); // Outputs: logo/logo0001_01.png (default behavior)

}
