import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DeviceDetectorService, DeviceInfo} from "ngx-device-detector";
import {Table} from "primeng/table";
import {AuthService} from "../../../shared/services/auth.service";

@Component({
  selector: 'app-table-caption',
  templateUrl: './table-caption.component.html',
  styleUrls: ['./table-caption.component.scss']
})
export class TableCaptionComponent implements OnInit {

  private deviceInfo: DeviceInfo;
  isMobile: boolean;

  private _isFiltered: boolean;
  get isFiltered(): boolean {
    return this._isFiltered;
  }
  set isFiltered(newValue) {
    this._isFiltered = newValue;
    this.isFilteredChange.emit(this._isFiltered);
  }
  @Output() isFilteredChange = new EventEmitter<boolean>();

  @Input() table: Table;
  selectedRecords: any[];
  stagedBadge: string;

  selectedColumns: any[];
  columns: any[];

  @Input() title: string = 'Title';

  // Button disable attributes
  @Input() addDisabled: boolean;
  @Input() adsDisabled: boolean;
  @Input() chooseColumnsDisabled: boolean;
  @Input() deleteDisabled: boolean;
  @Input() exportCsvDisabled: boolean;
  @Input() filterDisabled: boolean;
  @Input() mapDisabled: boolean;
  @Input() placesDisabled: boolean;
  @Input() resetStagedDisabled: boolean;
  @Input() saveStagedDisabled: boolean;
  @Input() usersDisabled: boolean;

  constructor(
    private readonly deviceService: DeviceDetectorService,
    public readonly authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.isMobile = this.deviceService.isMobile()
  }

  handleClearFilter(table: Table) {

  }

  globalFilter(table: Table, $event: Event) {

  }

  handlePlaces() {

  }

  handleAds() {

  }

  handleCsvExport() {

  }

  handleDelete() {

  }

  handleUsers() {

  }

  handleAdd() {

  }

  handleMap() {

  }

  handleResetStaged() {

  }

  handleSaveStaged() {

  }
}
