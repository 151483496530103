/*
 * Copyright © MEFTEK LLC. All rights reserved.
 * This information is confidential and proprietary to MEFTEK LLC
 * and may not be used, modified, copied or distributed.
 */

import {Component, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import * as placeConf from './place.conf';
import {firstValueFrom, lastValueFrom, Observable} from 'rxjs';
import {expandPlaces, initialPlaceValue, Place, preparePlace, updatePlaceDbVersion,} from './place.model';
import {Table} from 'primeng/table';
import {ConfirmationService, MessageService, SelectItem} from 'primeng/api';
import {AuthService} from '../../shared/services/auth.service';
import {initialVersion, Version} from '../../models/version.model';
import {animate, style, transition, trigger} from '@angular/animations';
import {UpdatePlaceComponent, UpdateType,} from './update-place/update-place.component';
import {environment} from '../../../environments/environment';
import {NGXLogger} from 'ngx-logger';
import {FirebaseService} from '../../services/firebase.service';
import {EnvTypeEnum, envTypeIsProd} from '../../models/env-type.enum';
import {Router} from "@angular/router";
import {DeviceDetectorService, DeviceInfo} from 'ngx-device-detector';
import {CsvExportService} from "../../services/csv-export.service";
import {DatePipe} from "@angular/common";
import {StorageService} from "../../shared/services/storage.service";
import {saveFilter, saveSort} from "../../shared/utilities";
import {
  AdminService,
  AppPermissions,
  defaultAppPermissions,
  defaultEmailAddresses,
  EmailAddresses
} from "../../services/admin-service";

@Component({
  selector: 'app-places',
  templateUrl: './place.component.html',
  styleUrls: ['./place.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        animate('1s ease-out', style({ height: 'auto', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ height: 'auto', opacity: 1 }),
        animate('1s ease-in', style({ height: 0, opacity: 0 })),
      ]),
    ]),
  ],
})
export class PlaceComponent implements OnInit {
  @ViewChild('placesTable') placesTable: Table;

  private _selectedColumns: any[];
  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }
  set selectedColumns(val: any[]) {
    //restore original order
    this._selectedColumns = val.sort((a,b) => a.index - b.index);// [...val];// this.columns.filter(col => val.includes(col));
  }

  EMPTY_TEXT = '---';

  appVersion: string;
  columns: any[] = placeConf.columnDefs;
  mapsColumns: any[] = placeConf.mapsColumnDefs;
  charmStatusDD: SelectItem[] = placeConf.charmStatusDD;
  currentPlace: Place;
  dbEnv: string;
  defaultZoom: number = 15;
  displayMap: boolean = false;
  displayUpdateForm: boolean = false;
  innerHeight: number;
  loading: boolean = false;
  loadingBody: boolean = true;
  places: Place[] = [];
  prodVersion: Version = initialVersion;
  progress: number = -1;
  rqcDD: SelectItem[] = placeConf.rqcsDD;
  staged: boolean = false;
  stagedCount: number = 0;
  stageVersion: Version = initialVersion;
  statesDD: SelectItem[] = [];
  statusDD: SelectItem[] = placeConf.statusDD;
  updateType: UpdateType;
  yesNoDD: SelectItem[] = placeConf.yesNoDD;
  yesNoTfDD: SelectItem[] = placeConf.yesNoTFDD;

  adminPermissions: AppPermissions = defaultAppPermissions;
  emailAddresses: EmailAddresses = defaultEmailAddresses;

  globalFilterActive: boolean;
  columnFiltersActive: boolean;
  sortActive: boolean;

  initialized: boolean;

  private deviceInfo: DeviceInfo;
  public isMobile: boolean;

  sortField: string = placeConf.defaultSortField;
  sortOrder: number = placeConf.defaultSortOrder;
  filters: any = {};

  constructor(
    public readonly authService: AuthService,
    private readonly confirmationService: ConfirmationService,
    private readonly firebaseService: FirebaseService,
    private readonly messageService: MessageService,
    private readonly logger: NGXLogger,
    private readonly router: Router,
    private readonly deviceService: DeviceDetectorService,
    private readonly csvExportService: CsvExportService,
    public readonly datePipe: DatePipe,
    private readonly storageService: StorageService,
    private readonly adminService: AdminService,
) {
    this.deviceInfo = deviceService.getDeviceInfo()
  }

  /**
   * Returns true if user edits should be disabled
   */
  // get editDisabled(): boolean {
  //   return !this.adminPermissions?.canStage?.includes(this.authService.userUid);
  // }

  /**
   * Returns true if user can view
   */
  get canEdit(): boolean {
    return this.adminPermissions.canEdit?.includes(this.authService.userUid);
  }

  /**
   * Returns true if user can stage data
   */
  get canStage(): boolean {
    return this.adminPermissions.canStage?.includes(this.authService.userUid);
  }

  /**
   * Returns true if user can view
   */
  get canView(): boolean {
    return this.adminPermissions.canView?.includes(this.authService.userUid);
  }

  /**
   * Returns true if user can save data
   */
  get canSave() : boolean {
    return this.adminPermissions.canSave?.includes(this.authService.userUid);
  }


  /**
   * Returns true if reset edits should be disabled
   */
  get resetStagedDisabled(): boolean {
    return !(this.staged && this.canStage);
  }

  /**
   * Returns true if saves should be disabled
   */
  get saveDisabled(): boolean {
    return !(this.staged && this.canSave);
  }

  /**
   * Returns staged places badge count
   */
  get stagedBadge(): string {
    return this.stagedCount ? String(this.stagedCount) : '';
  }

  /**
   * Returns true if selectedColumns has changed
   */
  get selectedColumnsChanged(): boolean {
    // TODO: This need to see if they are equal not in size but content
    return this.selectedColumns.length !== (this.isMobile ? placeConf.mobileColumnDefs.length : placeConf.columnDefs.length);
  }

  /**
   * Returns true if places are filtered
   */
  get isFiltered(): boolean {
    return this.globalFilterActive || this.columnFiltersActive || this.sortActive || this.selectedColumnsChanged;
  }

  /**
   * Calculate the progress in integer percent
   * @param count The current count
   * @param total The total count
   * @param percent The percent value to count up to, normally 100%
   * @private
   */
  public static calculateProgress(count: number, total: number, percent: number = 100): number {
    return Math.min(Math.round((count / total) * percent), percent);
  }

  protected statusMap: Map<string, string>;

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.logger.debug('Window Resize', event);
    this.innerHeight = window.innerHeight;
  }

  ngOnInit(): void {
    this.loading = true;
    this.appVersion = environment.app.version;
    this.dbEnv = environment.app.dbEnv;

    this.isMobile = this.deviceService.isMobile();

    this.selectedColumns = this.isMobile ? placeConf.mobileColumnDefs : placeConf.columnDefs;
    this.statusMap = this.createStatusMap();
    this.currentPlace = initialPlaceValue;
    this.innerHeight = window.innerHeight;

    this.getAdminPermissions();
    this.getEmailAddresses();
    this.getVersionProd();
    this.getVersionStage();
    this.getPlacesStage();

    // Retrieve settings from storage on initialization
    const savedFilters = this.storageService.getItem(StorageService.placesFilter);
    const savedSortField = this.storageService.getItem(StorageService.placesSortField);
    const savedSortOrder = this.storageService.getItem(StorageService.placesSortOrder);

    if (savedSortField) {
      this.sortField = savedSortField;
    }
    if (savedSortOrder) {
      this.sortOrder = savedSortOrder;
    }
    if (savedFilters) {
      this.filters = savedFilters;
    }

    setTimeout(() => {
      this.initialized = true;
    },1000);
  }

  /*** Button Handlers ***/

  /**
   * Handle the Add button click
   */
  handleAdd(): void {
    this.currentPlace = initialPlaceValue;
    this.updateType = UpdateType.ADD;
    this.displayUpdateForm = true;
  }

  /**
   * Handle cancel of an edited staged place object, but not saved
   * @param place The place object to cancel edit of
   */
  handleCancelEdit(place: Place): void {
    this.confirmationService.confirm({
      message: 'Are you sure? You will lose any pending changes.',
      accept: () => {
        this.cancelEdit(place);
      },
    });
  }

  /**
   * Clear all filters on the table
   * @param table The table that is filtered
   */
  handleClearFilter(table: Table): void {
    this.globalFilter(table, { target: { value: '' } });
    table.reset();
    table.sortField = placeConf.defaultSortField;
    table.sortOrder = placeConf.defaultSortOrder;
    table.sortSingle();
    this.selectedColumns = this.isMobile ? placeConf.mobileColumnDefs : placeConf.columnDefs;

  }

  /**
   * Hande Edit and display edit place form
   * @param place The place object to edit
   */
  handleEdit(place: Place): void {
    this.currentPlace = place;
    this.updateType = UpdateType.EDIT;
    this.displayUpdateForm = true;
  }

  /**
   * Handle the map button click
   */
  handleMap() {
    this.router.navigateByUrl('/map/usa').then();
  }

  /**
   * Handle the csv export button click
   */
  handleCsvExport() {
    this.csvExportService.exportMapCsvFiles(this.places, this.mapsColumns).then();
  }

  /**
   * Handle the Reset button click
   */
  handleResetStaged(): void {
    this.confirmationService.confirm({
      message: 'Are you sure? You will lose all staged changes.',
      accept: () => {
        this.resetStaged();
      },
    });
  }

  /**
   * Handle the Save button click
   */
  handleSaveProd(): void {
    this.confirmationService.confirm({
      message: 'Are you sure? This will save stage place to Production.',
      accept: () => {
        this.saveProd();
      },
    });
  }

  /**
   * Show the map of place
   * @param place The place object to show on map
   */
  handleShowMap(place: Place): void {
    this.currentPlace = place;
    this.displayMap = true;
  }

  /**
   * This method updates the database to handle Cheer Only version of app
   *   with partial backward compatibility
   */
  handleUpdateDb() {
    this.places.forEach(place => {
      // // Active, Retired, NULL
      // if (!isBlank(place.charm)) {
      //   place.charmStatus = place.charm
      // }
      // // Yes, No, NULL
      // if (!isBlank(place.cheer)) {
      //   place.cheerStatus = place.cheer?.toLowerCase() === 'y' ? 'A' : undefined;
      // }
      // // Active, Retired, Soon, Hidden
      // if (!isBlank(place.status)) {
      //   place.tagStatus = place.status;
      // }

      place.staged = true;
      const pPlace = updatePlaceDbVersion(place, EnvTypeEnum.STAGE);
      console.log('place:', place);
      console.log('pPlace:', pPlace);
      this.firebaseService.setPlaceStage(pPlace)
        .then(() => {
          // place.staged = false;
          console.log('pPlace written:', pPlace);
        });
    });
    this.updateStagedCount();
    this.staged = true;
  }

  formatChangesLog(newValue: any, oldValue: any): string {
    let formatted = `${newValue}`;
    if (oldValue != null) {
      formatted += `/${oldValue}`;
    }
    return formatted;
}
  changesLog(newPlace: Place, oldPlace?: Place): {} {
    const np = preparePlace(newPlace, EnvTypeEnum.STAGE);
    const op = oldPlace ? preparePlace(oldPlace, EnvTypeEnum.STAGE) : null;

    let logMsg = {};

    logMsg = {
      ...logMsg,
      id: this.formatChangesLog(np.id, op?.id), //`${np.id}/${op?.id ?? ''}`,
      name: this.formatChangesLog(np.name, op?.name) //`${np.name}/${op?.name ?? ''}`
    };
    if (np.address !== op?.address) {
      logMsg = {
        ...logMsg,
        address: this.formatChangesLog(np.address, op?.address) //`${np.address}/${op?.address ?? ''}`
      }
    }
    if (np.charm !== op?.charm) {
      logMsg = {
        ...logMsg,
        charm: this.formatChangesLog(np.charm, op?.charm) //`${np.charm}/${op?.charm ?? ''}`
      }
    }
    if (np.charmDesc !== op?.charmDesc) {
      logMsg = {
        ...logMsg,
        charmDesc: this.formatChangesLog(np.charmDesc, op?.charmDesc) //`${np.charmDesc}/${op?.charmDesc ?? ''}`
      }
    }
    if (np.cheer !== op?.cheer) {
      logMsg = {
        ...logMsg,
        cheer: this.formatChangesLog(np.address, op?.address) //`${np.cheer}/${op?.cheer ?? ''}`
      }
    }
    if (np.dog !== op?.dog) {
      logMsg = {
        ...logMsg,
        dog: this.formatChangesLog(np.dog, op?.dog) //`${np.dog}/${op?.dog ?? ''}`
      }
    }
    if (np.facebookURL !== op?.facebookURL) {
      logMsg = {
        ...logMsg,
        facebookURL: this.formatChangesLog(np.facebookURL, op?.facebookURL)
      }
    }
    if (np.instagramURL !== op?.instagramURL) {
      logMsg = {
        ...logMsg,
        instagramURL: this.formatChangesLog(np.instagramURL, op?.instagramURL) //`${np.instagramURL}/${op?.instagramURL ?? ''}`
      }
    }
    if ((np.latitude !== op?.latitude) || (np.longitude !== op?.longitude)) {
      let coordinates = `${np.latitude},${np.longitude}`;
      if (op != null) {
        coordinates += `\${op?.latitude},${op?.longitude}`
      }
      logMsg = {
        ...logMsg,
        coordinates
      }
    }
    if (np.phone !== op?.phone) {
      logMsg = {
        ...logMsg,
        phone: this.formatChangesLog(np.phone, op?.phone) //`${np.phone}/${op?.phone ?? ''}`
      }
    }
    if (np.recycle !== op?.recycle) {
      logMsg = {
        ...logMsg,
        recycle: this.formatChangesLog(np.recycle, op?.recycle) //`${np.recycle}/${op?.recycle ?? ''}`
      }
    }
    if (np.rqc !== op?.rqc) {
      logMsg = {
        ...logMsg,
        rqc: this.formatChangesLog(np.rqc, op?.rqc) //`${np.rqc}/${op?.rqc ?? ''}`
      }
    }
    if (np.sequence !== op?.sequence) {
      logMsg = {
        ...logMsg,
        sequence: this.formatChangesLog(np.sequence, op?.sequence) //`${np.sequence}/${op?.sequence ?? ''}`
      }
    }
    if (np.tagStatus !== op?.tagStatus) {
      logMsg = {
        ...logMsg,
        status: this.formatChangesLog(np.status, op?.status) //`${np.tagStatus}/${op?.tagStatus ?? ''}`
      }
    }
    if (np.twitterURL !== op?.twitterURL) {
      logMsg = {
        ...logMsg,
        twitterURL: this.formatChangesLog(np.twitterURL, op?.twitterURL) //`${np.twitterURL}/${op?.twitterURL ?? ''}`
      }
    }
    if (np.untappd !== op?.untappd) {
      logMsg = {
        ...logMsg,
        untappd: this.formatChangesLog(np.untappd, op?.untappd) //`${np.untappd}/${op?.untappd ?? ''}`
      }
    }
    if (np.websiteURL !== op?.websiteURL) {
      logMsg = {
        ...logMsg,
        websiteURL: this.formatChangesLog(np.websiteURL, op?.websiteURL) //`${np.websiteURL}/${op?.websiteURL ?? ''}`
      }
    }
    return logMsg;
  }

  sortObjectByKeys(o: any) {
    return Object.keys(o).sort().reduce((r: any, k) => {
      // noinspection CommaExpressionJS
      return (r[k] = o[k], r);
    }, {});
  }

  /**
   * This method creates the log object and saves from firebase
   * @param newPlace The new place data
   * @param oldPlace The old place data
   */
  dbLog(newPlace: Place, oldPlace?: Place) {
    const logObject = this.changesLog(newPlace, oldPlace);
    const logData = {
      description: 'TagaBrew App Data Staged',
      operation: `${oldPlace ? 'Updated (New/Old)' : 'Created'}`,
      changes: logObject
    };
    this.firebaseService.stageLog(newPlace.id, logData, this.prodVersion);

    const subject = `TagaBrew App Data Staged (${environment.app.version}${environment.app.dbEnv}) (DB:${this.stageVersion.number}/${this.stageVersion.date})`
    this.firebaseService.sendEmail(logData, this.emailAddresses.toEmailsStage, subject, true);
  }

  /**
   * Handle submitted place object (ADD or EDIT)
   * @param place The place object to process
   */
  handleSubmit(place: Place): void {
    this.submitPlace(place).then();
  }

  /**
   * Submit the place. Save to DB
   * @param place The place object to save
   */
  public async submitPlace(place: Place): Promise<void> {
    // Get the logo information and clear the logoData, we does save that to database. Save to storage
    const logoData = place.logoData;
    const logoUrl = place.logoUrl;
    place.logoData = undefined;
    if (logoData != null && logoUrl != null) {
      this.firebaseService.uploadLogoToStorage(logoUrl, logoData);
    }

    const preparedPlace = preparePlace(place, EnvTypeEnum.STAGE);
    const now = new Date();
    const nowString = this.datePipe.transform(now, "yyyy-MM-dd HH:mm:ss")!;
    preparedPlace.createdBy = preparedPlace.createdBy ? preparedPlace.createdBy : this.authService.userDisplayName;
    preparedPlace.createdDate = preparedPlace.createdDate ? preparedPlace.createdDate : nowString;
    // preparedPlace.createdDateValue = preparedPlace.createdDate ? new Date(preparedPlace.createdDate) : new Date(nowString);
    preparedPlace.updatedBy = this.authService.userDisplayName;
    preparedPlace.updatedDate = nowString;
    // preparedPlace.updatedDateValue = new Date(nowString);

    await this.firebaseService.setPlaceStage(preparedPlace)
      .then(() => {
        if (UpdatePlaceComponent.isAdd(this.updateType)) {
          // this.places.unshift(preparedPlace);  // TODO: removed to stop double add ???
          this.dbLog(preparedPlace);
        } else {
          const index = this.places.findIndex((p) => p.id === preparedPlace.id);
          if (index !== -1) {
            this.dbLog(preparedPlace, this.places[index]);
            this.places[index] = preparedPlace;
          }
        }
      })
      .catch((err) => {
          const errMsg = `Error Saving: ${place.name}, ${err}`;
          this.logger.error(errMsg);
          this.messageService.add({
            key: 'bc',
            life: 3000,
            severity: 'error',
            summary: 'Error',
            detail: errMsg,
          });
        });

    this.staged = true;
    const newVersion = this.firebaseService.createNewVersion(this.prodVersion, this.places.length);

    await this.firebaseService.setVersionStage(newVersion)
      .then(() => {
        this.stageVersion = newVersion;
      })
      .catch((error) => {
        const errMsg = `Error Saving new Version: ${error}`;
        this.logger.error(errMsg);
        this.messageService.add({
          key: 'bc',
          severity: 'error',
          summary: 'Error',
          detail: `${errMsg}`,
        });
      });

    this.places = [...this.places];
    this.placesTable?._filter();
    if (this.updateStagedCount() === 0) {
      this.staged = false;
    }
    this.messageService.add({
      key: 'bc',
      severity: 'success',
      summary: 'Success',
      detail: `${place.name} Successfully ${
        UpdatePlaceComponent.isAdd(this.updateType) ? 'Added' : 'Updated'
      }`,
    });
  }

  /**
   * Globally filter the table by $event
   * @param table The table to filter
   * @param $event The value to filter by (contains)
   */
  globalFilter(table: Table, $event: any): void {
    table.filterGlobal($event.target.value, 'contains');
    this.globalFilterActive = $event.target.value.length > 0;
  }

  /**
   * Return a style for the row based on normal, dirty or staged and status
   * @param row The row to style
   */
  styleRow(row: Place): string {
    let style: string;
    if (row.dirty) {
      style = 'dirty';
    } else if (row.staged) {
      style = 'staged';
    } else {
      style = 'not-dirty';
    }
    switch (row['tagStatus']) {
      case 'S':
        style += ' legend-soon';
        break;
      case 'R':
        style += ' legend-retired';
        break;
      case 'H':
        style += ' legend-hidden';
        break;
      default:
        style += ' legend-active';
        break;
    }

    return style;
  }

  /**
   * Get the values for the state dropdown
   */
  getStatesDD(): SelectItem[] {
    const statesDD: SelectItem[] = [];
    this.places.forEach((place) => {
      if (
        statesDD.findIndex((state) => {
          return place.state === state.label;
        }) === -1
      ) {
        statesDD.push({ label: place.state, value: place.state }); // getStateAbbreviation(place.state!)});
      }
    });
    statesDD.sort((a, b) => {
      return a.label! > b.label! ? 1 : a.label! < b.label! ? -1 : 0;
    });
    return statesDD;
  }

  /**
   * Returns true if the cancel edit is disabled
   * @param staged Value is true if the place object is staged
   */
  cancelEditDisabled(staged: boolean): boolean {
    return (
      !staged || !(staged && this.canStage)
    );
  }

  private resetVersionStageHelper(): void {
    firstValueFrom(this.firebaseService.resetVersionStage())
      .then((version) => {
        this.logger.info('reset version STAGE completed');
        if (version) {
          this.stageVersion = { ...version };
        }
      });
  }

  /**
   * Cancel staged edit of place. Reset from PROD
   * @param place The place object to cancel edit on
   * @private
   */
  private cancelEdit(place: Place): void {
    this.firebaseService.getPlaceByIdProd(place.id!)
      .then((p1) => {
        if (p1 == null) {
          // remove it
          this.firebaseService.deletePlaceByIdStage(place.id!)
            .then(() => {
              this.places = this.places.filter((p2) => p2.id !== place.id);
              if (this.updateStagedCount() === 0) {
                this.staged = false;
                this.resetVersionStageHelper();
              }
            })
            .catch((err) => {
              const errMsg = `Error (01) Cancelling Edit of ${place.name}, ${err}`;
              this.logger.error(errMsg);
              this.messageService.add({
                key: 'bc',
                severity: 'error',
                summary: 'Error',
                detail: errMsg,
              });
            });
        } else {
          // Update it
          const index = this.places.findIndex((p2) => p2.id === p1?.id);
          if (index !== -1) {
            const p2 = preparePlace(p1, EnvTypeEnum.STAGE);
            this.firebaseService.setPlaceStage(p2)
              .then(() => {
                this.places[index] = this.setCreatedUpdatedDateValues(p1);
                this.places = [...this.places];
                if (this.updateStagedCount() === 0) {
                  this.staged = false;
                  this.resetVersionStageHelper();
                }
              })
              .catch((err) => {
                const errMsg = `Error (02) Cancelling Edit of ${place.name}, ${err}`;
                this.logger.error(errMsg);
                this.messageService.add({
                  key: 'bc',
                  life: 3000,
                  severity: 'error',
                  summary: 'Error',
                  detail: errMsg,
                });
              });
          } else {
            const errMsg = `Error (03) Cancelling Edit of ${place.name}`;
            this.logger.error(errMsg);
            this.messageService.add({
              key: 'bc',
              life: 3000,
              severity: 'error',
              summary: 'Error',
              detail: errMsg,
            });
          }
        }
      })
      .catch((err) => {
        const errMsg = `Error (04) Cancelling Edit of ${place.name}, ${err}`;
        this.logger.error(errMsg);
        this.messageService.add({
          key: 'bc',
          life: 3000,
          severity: 'error',
          summary: 'Error',
          detail: errMsg,
        });
      });
  }

  /**
   * Get array of place objects from STAGE database
   * @private
   */
  private getPlacesStage(): void {
    this.loading = true;
    this.firebaseService.getPlacesStage().subscribe(
      {
        next: places => {
          this.places = expandPlaces(places);

          if (this.updateStagedCount() > 0) {
            this.staged = true;
          }
          this.statesDD = this.getStatesDD();
          this.logger.debug('Places (STAGE):', places);
          this.loading = false;
          this.messageService.add({
            key: 'bc',
            life: 3000,
            severity: 'success',
            summary: 'Success',
            detail: 'Places Successfully Loaded',
          });
        },
        error: err => {
          const errMsg = `Places Load Failed, ${err}`;
          this.logger.error(errMsg);
          this.loading = false;
          this.messageService.add({
            key: 'bc',
            life: 3000,
            severity: 'error',
            summary: 'Error',
            detail: errMsg,
          });
        }
      }
    );
  }

  /**
   * Get admin properties from database
   * @private
   */
  private getAdminPermissions(): void {
    this.adminService.getAppPermissions()
      .then((appPermissions) => {
        if (appPermissions) {
          this.adminPermissions = appPermissions;
          this.logger.info('Admin App Permissions:', appPermissions);
        }
      }).catch((err) => {
      const errMsg = `Error getting admin permissions, edits are disabled, ${err}`;
      this.logger.error(errMsg);
      this.messageService.add({
        key: 'bc',
        life: 3000,
        severity: 'error',
        summary: 'Error',
        detail: errMsg,
      });
    });

    // this.firebaseService.getAdminPermissions()
    //   .then((adminPermissions) => {
    //     if (adminPermissions) {
    //       this.adminPermissions = adminPermissions;
    //       this.logger.info('Admin Permissions:', adminPermissions);
    //     }
    //   })
    //   .catch((err) => {
    //     const errMsg = `Error getting admin permissions, edits are disabled, ${err}`;
    //     this.logger.error(errMsg);
    //     this.messageService.add({
    //       key: 'bc',
    //       severity: 'error',
    //       summary: 'Error',
    //       detail: errMsg,
    //     });
    //   });
  }

  /**
   * Get admin properties from database
   * @private
   */
  private getEmailAddresses(): void {
    this.adminService.getEmailAddresses()
      .then((emailAddresses) => {
        if (emailAddresses) {
          this.emailAddresses = emailAddresses;
          this.logger.info('Admin Email Addresses:', JSON.stringify(emailAddresses));
        }
      }).catch((err) => {
      const errMsg = `Error getting email addresses, notifications are disabled, ${err}`;
      this.logger.error(errMsg);
      this.messageService.add({
        key: 'bc',
        life: 3000,
        severity: 'error',
        summary: 'Error',
        detail: errMsg,
      });
    });

    // this.firebaseService.getEmailsAddresses()
    //   .then((emailAddresses) => {
    //     if (emailAddresses) {
    //       this.emailAddresses = emailAddresses;
    //       this.logger.info('Email Addresses:', emailAddresses);
    //     }
    //   })
    //   .catch((err) => {
    //     const errMsg = `Error getting email addresses, edits are disabled, ${err}`;
    //     this.logger.error(errMsg);
    //     this.messageService.add({
    //       key: 'bc',
    //       severity: 'error',
    //       summary: 'Error',
    //       detail: errMsg,
    //     });
    //   });
  }

  /**
   * Get the version of the database
   * @param envType The environment to get from (STAGE or PROD)
   * @private
   */
  private getVersion(envType: EnvTypeEnum): void {
    firstValueFrom(
      this.firebaseService.getVersion(envType).valueChanges())
      .then((version) => {
        if (version) {
          if (envTypeIsProd(envType)) {
            this.prodVersion = { ...version };
          } else {
            this.stageVersion = { ...version };
          }
        }
        this.logger.info(`Version (${envType}):`, version);
      })
      .catch((err) => {
        const errMsg = `Error getting (${envType}) version, ${err}`;
        this.logger.error(errMsg);
        this.messageService.add({
          key: 'bc',
          life: 3000,
          severity: 'error',
          summary: 'Error',
          detail: errMsg,
        });
      });
  }

  /**
   * Get version of the PROD database
   * @private
   */
  private getVersionProd(): void {
    this.getVersion(EnvTypeEnum.PROD);
  }

  /**
   * Get version of the STAGE database
   * @private
   */
  private getVersionStage(): void {
    this.getVersion(EnvTypeEnum.STAGE);
  }

  /**
   * Reset Staged from PROD
   */
  resetStaged() {
    this.loading = true;
    this.progress = 0; // Start the progress bar

    // Get Places to reset
    const placeIds: number[] = [];
    this.places.forEach((place) => {
      if (place.staged) {
        placeIds.push(place.id);
      }
    });

    // Get places from Prod
    // const placeIdsToReset: number[] = [];
    const placeIdsToDelete: number[] = [];

    this.firebaseService.getPlacesByIdsProd(placeIds)
      .subscribe({
        next: (places) => {
          // If places is null then there are no places to reset,
          // only new places to delete from STAGE table
          placeIds.forEach((placeId) => {
            const index = places.findIndex(place => place.id === placeId);
            if (index !== -1) {
              // placeIdsToReset.push(placeId);
            } else {
              placeIdsToDelete.push(placeId);
            }
          });

          this.progress = 33;
        },
        complete: () => {
          firstValueFrom(this.firebaseService.getPlacesByIdsProd(placeIds))
            .then(places => {
              this.firebaseService.setPlacesStage(this.places, places)
                .subscribe({
                  next: (place) => {
                    if (place != null) {
                      const index = this.places.findIndex((p) => p.id === place.id);
                      if (index !== -1) {
                        this.places[index] = this.setCreatedUpdatedDateValues(place);
                      }
                    }
                  },
                  complete: () => {
                    this.places = [...this.places];
                    this.progress = 66;
                    lastValueFrom(this.deletePlacesStage(placeIdsToDelete))
                      .then(() => {
                        if (placeIdsToDelete.length > 0) {
                          this.places = this.places.filter((place) => {
                            return (placeIdsToDelete.findIndex((placeId) => placeId === place.id) === -1);
                          });
                        }
                        this.progress = 100;

                        // Let the progress go to 100 before hiding it
                        setTimeout(() => {
                          this.loading = false;
                          this.progress = -1;
                          if (this.updateStagedCount() === 0) {
                            this.staged = false;
                            this.resetVersionStageHelper();
                          }
                          this.messageService.add({
                            key: 'bc',
                            life: 3000,
                            severity: 'success',
                            summary: 'Success',
                            detail: 'STAGED Database Successfully Reset',
                          });
                        }, 100);
                      });
                  },
                  error: err => {
                    const errMsg = `Error Resetting STAGED Changes, ${err}`;
                    this.logger.error(errMsg);
                    this.progress = -1;
                    this.loading = false;
                    this.messageService.add({
                      key: 'bc',
                      life: 3000,
                      severity: 'error',
                      summary: 'Error',
                      detail: errMsg,
                    });
                  }
                });
            })
            .catch(err => {
              const errMsg = `Error saving places Staged, ${err}`;
              this.logger.error(errMsg);
              this.progress = -1;
              this.loading = false;
              this.messageService.add({
                key: 'bc',
                life: 3000,
                severity: 'error',
                summary: 'Error',
                detail: errMsg,
              });
            });
        },
        error: (err) => {
          const errMsg = `Error Resetting Staged, ${err}`;
          this.logger.error(errMsg);
          this.progress = -1;
          this.loading = false;
          this.messageService.add({
            key: 'bc',
            life: 3000,
            severity: 'error',
            summary: 'Error',
            detail: errMsg,
          });
        }
      });
  }

  private setCreatedUpdatedDateValues(place: Place) {
    return {
      ...place,
      createdDateValue: place.createdDate ? new Date(place.createdDate) : undefined,
      updatedDateValue: place.updatedDate ? new Date(place.updatedDate) : undefined,
    };
  }

  /**
   * Save Places to PROD
   * @private
   */
  private saveProd(): void {
    this.loading = true;
    this.progress = 0; // Start the progress bar

    // Get Places to save
    const placeIdsToSave: number[] = [];
    this.places.forEach((place) => {
      if (place.staged) {
        placeIdsToSave.push(place.id);
      }
    });

    let count = 0;
    const total = placeIdsToSave.length;

    // const changesPublished: ChangesPublished[] = [];
    const changesPublished: Place[] = [];

    this.firebaseService.setPlacesByIdsProd(this.places, placeIdsToSave)
      .subscribe({
        next: (place) => {
          this.firebaseService.setPlaceStage(place)
            .then(() => {
              const index = this.places.findIndex((p) => p.id === place.id);
              if (index !== -1) {
                this.places[index] = place;
                // changesPublished.push({name: place.name, status: place.status ?? 'Unknown'});
                changesPublished.push(place);
              }
              count = count + 1;
              this.progress = PlaceComponent.calculateProgress(count, total);
              if (count === total) {
                setTimeout(() => {
                  this.progress = 100;
                  this.places = [...this.places];
                  if (this.updateStagedCount() === 0) {
                    this.staged = false;
                  }
                  const newVersion = this.firebaseService.createNewVersion(
                    this.prodVersion,
                    this.places.length
                  );
                  this.firebaseService.setVersionProd(newVersion).then(() => {
                    this.logger.info('set version PROD completed');
                    this.stageVersion = { ...newVersion };
                  });
                  this.getVersionProd();

                  // Let the progress go to 100 before hiding it
                  setTimeout(() => {
                    this.progress = -1; // Stop the progress bar
                    this.loading = false;
                    this.messageService.add({
                      key: 'bc',
                      life: 3000,
                      severity: 'success',
                      summary: 'Success',
                      detail: 'PROD Database Successfully Updated',
                    });
                    this.firebaseService.publishLog(this.prodVersion, changesPublished, this.emailAddresses.toEmailsProd);
                  }, 100);
                }, 100);
              }
            })
            .catch(err => {
              const errMsg = `Error Saving ${place.id}: ${place.name} to STAGE Database, ${err}`;
              this.logger.error(errMsg);
              this.progress = -1;
              this.loading = false;
              this.messageService.add({
                key: 'bc',
                life: 3000,
                severity: 'error',
                summary: 'Error',
                detail: errMsg,
              });
            })
        },
        complete: () => {
          // this.progress = 100;
          // this.places = [...this.places];
          // if (this.updateStagedCount() === 0) {
          //   this.staged = false;
          // }
          // const newVersion = this.firebaseService.createNewVersion(this.prodVersion, this.places.length);
          // this.firebaseService.setVersionProd(newVersion)
          //   .then(() => {
          //     this.logger.info('set version PROD completed');
          //     this.stageVersion = { ...newVersion };
          //   });
          // this.getVersionProd();
          //
          // // Let the progress go to 100 before hiding it
          // setTimeout(() => {
          //   this.progress = -1; // Stop the progress bar
          //   this.loading = false;
          //   this.messageService.add({
          //     key: 'bc',
          //     severity: 'success',
          //     summary: 'Success',
          //     detail: 'PROD Database Successfully Updated',
          //   });
          //   }, 100);
        },
        error: (err) => {
          const errMsg = `Error Saving PROD Database, ${err}`;
          this.logger.error(errMsg);
          this.progress = -1;
          this.loading = false;
          this.messageService.add({
            key: 'bc',
            life: 3000,
            severity: 'error',
            summary: 'Error',
            detail: errMsg,
          });
        },
      });
  }

  /**
   * Update count of staged places
   * @private
   */
  private updateStagedCount(): number {
    let count = 0;
    this.places.forEach((place, index) => {
      if (place.staged) {
        count++;
        this.logger.debug(count, index);
      }
    });
    this.stagedCount = count;
    return count;
  }

  /**
   * Delete STAGE places matching values in placeIdsToDelete
   * @param placeIdsToDelete Array of placeIds to deleted
   * @private
   */
  private deletePlacesStage(placeIdsToDelete: number[]): Observable<void> {
    return this.firebaseService.deletePlacesByIdStage(placeIdsToDelete);
  }

  /**
   * Update filter when entering filter text for table
   * @param $event The event data for the filter
   */
  handleFilter($event: any) {
    this.columnFiltersActive = saveFilter(
      this.storageService,
      StorageService.placesFilter,
      $event
    );
  }

  /**
   * This method converts and returns a string in PascalCase
   * @param s The string to convert
   */
  pascalCase(s: string): string {
    return s.replace(/(\w)(\w*)/g,
      function(g0,g1,g2){return g1.toUpperCase() + g2.toLowerCase();});
  }

  statusName(status: string): string {
    return this.statusMap.get(status) ?? this.EMPTY_TEXT;
  }

  private createStatusMap(): Map<string, string> {
    const statusMap = new Map<string, string>();
    statusMap.set('A', 'Active');
    statusMap.set('H', 'Hidden');
    statusMap.set('O', 'Out of Stock');
    statusMap.set('R', 'Retired');
    statusMap.set('S', 'Soon');
    statusMap.set('T', 'Temp Closed');
    statusMap.set('C', 'Closed');
    statusMap.set('Q', 'Quit');
    statusMap.set('Y', 'Yes');
    return statusMap;
  }

  handleUsers() {
    this.router.navigate(['/user-profile']).then();
  }

  handleAds() {
    this.router.navigate(['/ads-list']).then();
  }

  handleSort($event: any) {
    this.sortActive = saveSort(
      this.storageService,
      placeConf.defaultSortField,
      placeConf.defaultSortOrder,
      StorageService.placesSortField,
      StorageService.placesSortOrder,
      $event
    );
  }

  blockedUI: boolean;
  handleFirestoreAddPlaces() {
    this.blockedUI = true;
    this.firebaseService.addMultiplePlaces(this.places, EnvTypeEnum.STAGE)
      .then(() => {
        this.blockedUI = false;
      })
      .catch(error => {
        const errMsg = `Error Saving place to stage firestore, ${error.toLocaleString()}`;
        this.logger.error(errMsg);
        this.messageService.add({
          key: 'bc',
          life: 3000,
          severity: 'error',
          summary: 'Error',
          detail: errMsg,
        });
        this.blockedUI = false;
      });
    this.firebaseService.addMultiplePlaces(this.places, EnvTypeEnum.PROD)
      .then(() => {
        this.blockedUI = false;
      })
      .catch(error => {
        const errMsg = `Error Saving place to prod firestore, ${error.toLocaleString()}`;
        this.logger.error(errMsg);
        this.messageService.add({
          key: 'bc',
          life: 3000,
          severity: 'error',
          summary: 'Error',
          detail: errMsg,
        });
        this.blockedUI = false;
      });
    this.firebaseService.addVersion(this.stageVersion, EnvTypeEnum.STAGE).then();
    this.firebaseService.addVersion(this.prodVersion, EnvTypeEnum.PROD).then();
  }
}
