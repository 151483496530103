/*
 * Copyright © MEFTEK LLC. All rights reserved.
 * This information is confidential and proprietary to MEFTEK LLC
 * and may not be used, modified, copied or distributed.
 */

import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  expandPlace,
  formatNumbers,
  initialPlaceValue,
  Place,
  setCheer,
  setStatus,
  statuses,
} from 'src/app/components/place/place.model';
import * as placeConf from './../place.conf';
import {SelectItem} from 'primeng/api';
import {NgModel} from '@angular/forms';
import {FirebaseService} from '../../../services/firebase.service';
import {Observable} from "rxjs";
import {LogoNameGenerator} from "../file-upload/file-upload.component";

export enum UpdateType {
  ADD,
  EDIT,
}

declare var google: any;

@Component({
  selector: 'app-update-place',
  templateUrl: './update-place.component.html',
  styleUrls: ['./update-place.component.scss'],
})
export class UpdatePlaceComponent implements OnInit, AfterViewInit {
  @Input() place: Place;
  @Input() places: Place[];
  @Input() updateType: UpdateType;

  @Output() submitEvent = new EventEmitter<Place>();
  @Output() visibleChange = new EventEmitter<boolean>();

  @ViewChild('placeForm') placeForm: any;

  currentPlace = initialPlaceValue;
  name: string = '';
  statesDD: SelectItem[] = placeConf.statesDD;
  statusDD: SelectItem[] = placeConf.statusDD;
  charmStatusDD: SelectItem[] = placeConf.charmStatusDD;
  rqcsDD: SelectItem[] = placeConf.rqcsDD;
  logoUrl$: Observable<any>;


  constructor(
    private readonly elementRef:ElementRef,
    private readonly firebaseService: FirebaseService,
    // private readonly sanitizer: DomSanitizer,
  ) {
  }

  setStatus1(tagStatus?: string, cheerStatus?: string): string | undefined {
    return setStatus(tagStatus, cheerStatus);
  }

  setCheer1(cheerStatus?: string): string | undefined {
    return setCheer(cheerStatus);
  }

  ngOnInit(): void {
    this.place = this.place ?? initialPlaceValue;
    this.places = this.places ?? [];
    this.updateType = this.updateType ?? UpdateType.EDIT;

    // Add Hidden to DD if tag/cheer already exists, and we are EDIT
    if (this.updateType === UpdateType.EDIT) {
      if (this.place.tagStatus != null || this.place.cheerStatus != null) {
        this.statusDD = placeConf.statusWithHiddenDD;
      }
    }
  }

  ngAfterViewInit() {
     const digitPeriodRegExp = new RegExp('\\d|\\.|\-|\,');
    this.elementRef.nativeElement.querySelector('#coordinates')
       .addEventListener('keydown',
       function (event: any) {
         if(event.ctrlKey || event.altKey
           || typeof event.key !== 'string'
           || event.key.length !== 1) {
           return;
         }

         if(!digitPeriodRegExp.test(event.key)) {
           console.log(1);
           event.preventDefault();
         }
       }, false);
  }

  private _visible: boolean = false;
  @Input()
  get visible(): boolean {
    return this._visible;
  }
  set visible(value: boolean) {
    this._visible = value;
    this.visibleChange.emit(this._visible);
  }

  get isDogFriendly(): boolean {
    return this.currentPlace.dog
      ? this.currentPlace.dog === statuses.yes
      : false;
  }

  set isDogFriendly(value: boolean) {
    this.currentPlace.dog = value ? statuses.yes : '';
  }

  get recycle(): boolean {
    return this.currentPlace.recycle
      ? this.currentPlace.recycle === statuses.yes
      : false;
  }

  set recycle(value: boolean) {
    this.currentPlace.recycle = value ? statuses.yes : '';
  }

  get isActive(): boolean {
    return this.currentPlace.tagStatus === statuses.active;
  }

  set isActive(value: boolean) {
    this.currentPlace.tagStatus = value ? statuses.active : undefined;
  }

  get isSoon(): boolean {
    return this.currentPlace.tagStatus === statuses.soon;
  }

  set isSoon(value: boolean) {
    this.currentPlace.tagStatus = value ? statuses.soon : undefined;
  }

  get isRetired(): boolean {
    return this.currentPlace.tagStatus === statuses.retired || this.currentPlace.cheerStatus === statuses.retired;
  }

  set isRetired(value: boolean) {
    this.currentPlace.tagStatus = value ? statuses.retired : undefined;
  }

  get isHidden(): boolean {
    return this.currentPlace.tagStatus === statuses.hidden;
  }

  set isHidden(value: boolean) {
    this.currentPlace.tagStatus = value ? statuses.hidden : undefined;
  }

  get hasCharm(): boolean {
    return this.currentPlace.charm
      ? this.currentPlace.charm === statuses.active ||
      this.currentPlace.charm === statuses.retired
      : false;
  }

  set hasCharm(value: boolean) {
    this.currentPlace.charm = value ? statuses.active : '';
  }

  get hasStatus(): boolean {
    return (this.currentPlace.tagStatus != null && this.currentPlace.tagStatus?.length > 0)
      || (this.currentPlace.cheerStatus != null && this.currentPlace.cheerStatus.length > 0);
  }

  get isStaged(): boolean {
    return this.currentPlace.staged ?? false;
  }

  set isStaged(value: boolean) {
    this.currentPlace.staged = value;
  }

  get facebookDomain() {
    return 'facebook.com';
  }

  get instagramDomain() {
    return 'instagram.com';
  }

  get twitterDomain() {
    return 'twitter.com';
  }

  get untappdDomain() {
    return 'untappd.com';
  }

  get facebookUrlPlaceholder(): string {
    return UpdatePlaceComponent.urlPlaceholder(this.facebookDomain);
  }

  get instagramUrlPlaceholder(): string {
    return UpdatePlaceComponent.urlPlaceholder(this.instagramDomain);
  }

  get twitterUrlPlaceholder(): string {
    return UpdatePlaceComponent.urlPlaceholder(this.twitterDomain);
  }

  get untappdUrlPlaceholder(): string {
    return UpdatePlaceComponent.urlPlaceholder(this.untappdDomain);
  }

  get logoUrlPlaceholder(): string {
    return "logo/logo###[_##].png";
  }

  get arePlacesIdentical(): boolean {
    const place1 = this.currentPlace;
    const place2 = this.place;
    const keysToIgnore = new Set([
      'dirty',
      'street',
      'zip',
      'staged',
      'createdBy',
      'createdDate',
      'updatedBy',
      'updatedDate',
      'coordinates',
      'createdDateValue',
      'updatedDateValue',
      'key',
    ]);

    for (const key in place1) {
      if (place1.hasOwnProperty(key) && !keysToIgnore.has(key)) {
        const value1 = place1[key as keyof Place];
        const value2 = place2[key as keyof Place];

        // Treat empty strings and undefined as equal
        const areEqual = (v1: any, v2: any) => (v1 === v2) ||
          (v1 === "" && v2 == null) || (v1 == null && v2 === "");

        if (!areEqual(value1, value2)) {
          return false; // Return false if any relevant property differs
        }
      }
    }

    return true; // Return true if all relevant properties are identical
  }

  get comparePlaces(): string[] {
    const place1 = this.currentPlace;
    const place2 = this.place;
    const differences: string[] = [];
    const keysToIgnore = new Set([
      'dirty',
      'street',
      'zip',
      'staged',
      'createdBy',
      'createdDate',
      'updatedBy',
      'updatedDate',
      'coordinates',
      'createdDateValue',
      'updatedDateValue',
      'key',
    ]);

    for (const key in place1) {
      if (place1.hasOwnProperty(key) && !keysToIgnore.has(key)) {
        const value1 = place1[key as keyof Place];
        const value2 = place2[key as keyof Place];

        if (value1 !== value2) {
          differences.push(`Difference in ${key}: ${value1} !== ${value2}`);
        }
      }
    }

    if (differences.length === 0) {
      differences.push("No differences found; the places are identical.");
    }

    return differences;
  }

  get isDirty(): boolean {
    return !this.arePlacesIdentical;
    // // this.currentPlace.phone = UpdatePlaceComponent.unformatPhone(this.currentPlace.phone);
    // const currentDirty = this.currentPlace.dirty;
    // const currentStreet = this.currentPlace.street;
    // const currentZip = this.currentPlace.zip;
    // this.currentPlace.dirty = this.place.dirty;
    // this.currentPlace.street = this.place.street;
    // this.currentPlace.zip = this.place.zip;
    //
    // const newValue = JSON.stringify(expandPlace(this.currentPlace));
    // const orgValue = JSON.stringify(expandPlace(this.place));
    //
    // // this.currentPlace.phone = this.formatPhone(this.currentPlace.phone);
    // this.currentPlace.dirty = currentDirty;
    // this.currentPlace.street = currentStreet;
    // this.currentPlace.zip = currentZip;
    // return newValue !== orgValue;
  }

  /**
   * Returm true if ADDing place, else false
   * @param updateType ADD or EDIT place
   */
  static isAdd(updateType: UpdateType): boolean {
    return updateType === UpdateType.ADD;
  }

  /**
   * Returm true if EDITing place, else false
   * @param updateType ADD or EDIT place
   */
  static isEdit(updateType: UpdateType): boolean {
    return updateType === UpdateType.EDIT;
  }

  private static addressTokens(address: string): any {
    const addressArray = address.trim().split(',');
    const street = addressArray[0]?.trim() ?? '';
    const city = addressArray[1]?.trim() ?? '';
    const stateZipArray = addressArray[2]?.trim().split(/\s+/) ?? [];
    let state = stateZipArray[0]?.trim() ?? '';
    state = placeConf.getStateAbbreviation(state);
    const zip = stateZipArray[1]?.trim() ?? '';
    return {street: street, city: city, state: state, zip: zip};
  }

  private static urlPlaceholder(domain: string): string {
    return `https://${domain}/site`;
  }

  onChangeAddress(address: string): void {
    this.parseAddress(address);
    this.currentPlace.dirty = true;
  }

  onChangeStreetCityStateZip(): void {
    this.joinAddress();
    this.currentPlace.dirty = true;
  }

  onChangeState(state: string) {
    this.onChangeStreetCityStateZip();
    if (UpdatePlaceComponent.isAdd(this.updateType)) {
      this.setSequence(state);
    }
    this.currentPlace.dirty = true;
  }

  onChangeCoordinates(coordinates: string): void {
    let latLon: any[];
    if (coordinates.length === 0) {
      latLon = [undefined, undefined];
    } else {
      latLon = coordinates.replace(/\s\s+/g, '').trim().split(',');
    }
    if (latLon.length < 1) {
      latLon.push(undefined);
    }
    if (latLon.length < 2) {
      latLon.push(undefined);
    }
    let lat: number | undefined = Number(latLon[0]);
    if (isNaN(lat) || lat == null) {
      lat = undefined;
    }
    let lon: number | undefined = Number(latLon[1]);
    if (isNaN(lon) || lon == null) {
      lon = undefined;
    }
    this.currentPlace.latitude = lat;
    this.currentPlace.longitude = lon;
    this.currentPlace.coordinates = this.formatCoordinates(this.currentPlace.latitude, this.currentPlace.longitude);
    this.currentPlace.dirty = this.isDirty;
  }

  onChangeLatLon($event: any, latLon: string): void {
    const lat = latLon === 'lat' ? $event.value : this.currentPlace.latitude;
    const lon = latLon === 'lon' ? $event.value : this.currentPlace.longitude;
    this.currentPlace.coordinates = this.formatCoordinates(lat, lon);
    this.currentPlace.dirty = this.isDirty;
  }

  onChangeCharm(charmStatus: string): void {
    this.currentPlace.charmStatus = charmStatus;
    if (charmStatus == null) {
      this.currentPlace.charmDesc = undefined;
    } else if (
      charmStatus !== '' &&
      (this.currentPlace.charmDesc == null ||
        this.currentPlace.charmDesc === '')
    ) {
      this.currentPlace.charmDesc = this.place.charmDesc;
    }
    this.currentPlace.dirty = this.isDirty;
  }

  onChangePhone(phone: string): void {
    this.currentPlace.phone = phone;
    this.currentPlace.dirty = this.isDirty;
  }

  onChangeSequence(sequence: number) {
    this.currentPlace.sequence = sequence;
    this.currentPlace.dirty = this.isDirty;
  }

  onChangeName(name: string) {
    this.currentPlace.name = name;
    this.currentPlace.dirty = this.isDirty;
  }

  onChangeTagStatus(tagStatus?: string) {
    this.currentPlace.tagStatus = tagStatus;
    if (tagStatus === statuses.hidden) {
      this.currentPlace.cheerStatus = statuses.hidden;
    } else {
      if (this.currentPlace.cheerStatus === statuses.hidden) {
        this.currentPlace.cheerStatus = undefined;
      }
    }
    // this.toggleTagaCheerOny();
    this.currentPlace.dirty = this.isDirty;
  }

  onChangeCheerStatus(cheerStatus?: string) {
    this.currentPlace.cheerStatus = cheerStatus;
    if (cheerStatus != null) {
      // cheerStatus = AHORST
      if (statuses.allActive.includes(cheerStatus) && this.currentPlace.tagStatus === statuses.hidden) {
        // cheerStatus = AOT and tagStatus = H
        this.currentPlace.tagStatus = undefined;
      } else if (cheerStatus === statuses.hidden) {
        // cheerStatus = H
        this.currentPlace.tagStatus = statuses.hidden;
      } else if (this.currentPlace.tagStatus === statuses.hidden) {
        // cheerStatus = SR and tagStatus = H
        this.currentPlace.tagStatus = undefined;
      }
    } else {
      // cheerStatus = null | undefined
      this.onChangeTagStatus(this.currentPlace.tagStatus);
    }
    // this.toggleTagaCheerOny();
    this.currentPlace.dirty = this.isDirty;
  }

  onChangeCharmDesc(charmDesc: string) {
    this.currentPlace.charmDesc = charmDesc;
    this.currentPlace.dirty = this.isDirty;
  }

  onChangeDog(isDogFriendly: boolean) {
    this.isDogFriendly = isDogFriendly;
    this.currentPlace.dirty = this.isDirty;
  }

  onChangeRecycle(recycle: boolean) {
    this.recycle = recycle;
    this.currentPlace.dirty = this.isDirty;
  }

  onChangeRqc(rqc: string) {
    this.currentPlace.rqc = rqc;
    this.currentPlace.dirty = this.isDirty;
  }

  onChangeFacebookURL(facebookURL: string) {
    this.currentPlace.facebookURL = facebookURL;
    this.currentPlace.dirty = this.isDirty;
  }

  onChangeInstagramURL(instagramURL: string) {
    this.currentPlace.instagramURL = instagramURL;
    this.currentPlace.dirty = this.isDirty;
  }

  onChangeTwitterURL(twitterURL: string) {
    this.currentPlace.twitterURL = twitterURL;
    this.currentPlace.dirty = this.isDirty;
  }

  onChangeUntappd(untappd: string) {
    this.currentPlace.untappd = untappd;
    this.currentPlace.dirty = this.isDirty;
  }

  onChangeWebsiteURL(websiteURL: string) {
    this.currentPlace.websiteURL = websiteURL;
    this.currentPlace.dirty = this.isDirty;
  }

  onChangeLogoUrl(logoUrl: string) {
    this.currentPlace.logoUrl = logoUrl;
    this.currentPlace.dirty = this.isDirty;
  }

  onShow(): void {
    const lat = this.place.latitude;
    const lon = this.place.longitude;
    this.currentPlace = {
      ...this.place,
      charm: this.place.charm ?? '',
      cheer: this.place.cheer ?? '',
      coordinates: this.formatCoordinates(lat, lon),
      dog: this.place.dog ?? '',
      phone: this.place.phone ?? '',
    };

    this.logoUrl$ = this.firebaseService.getLogoByUrl(this.currentPlace.logoUrl);

    if (UpdatePlaceComponent.isAdd(this.updateType)) {
      this.setId();
    } else {
      this.name = this.currentPlace.name!;
      this.parseAddress(this.currentPlace.address!);
    }
  }

  handleBlurAddress(): void {
    const tokens = UpdatePlaceComponent.addressTokens(
      this.currentPlace.address!
    );
    this.currentPlace.address = `${tokens.street}, ${
      tokens.city
    }, ${tokens.state.toUpperCase()} ${tokens.zip}`;
  }

  handleBlurCoordinates() {
    return;
    // this.currentPlace.coordinates = this.formatCoordinates(this.currentPlace.latitude, this.currentPlace.longitude);
  }

  handleCancel(): void {
    this.visible = false;
  }

  handleSubmit(): void {
    this.submitEvent.emit(this.currentPlace);
    this.visible = false;
  }

  /**
   * true if the field is invalid, else false
   * @param field to validate
   */
  isFieldInvalid(field: NgModel) {
    if (field.name === 'coordinates') {
      const coordsRegEx = new RegExp('^-*\\d+\\.\\d+\,-*\\d+\\.\\d+$');
      return !coordsRegEx.test(field.value);
    }
    return field.invalid; // && (field.dirty || field.touched)
  }

  isTagStatusAndCheerStatusInvalid(tagStatus: NgModel, cheerStatus: NgModel) {
    return (tagStatus.value == null || tagStatus.value.length === 0) &&
           (cheerStatus.value == null || cheerStatus.value.length === 0);
  }

  imageDataUrlChange(data: string) {
    this.currentPlace.logoUrl = LogoNameGenerator.generateNewFilename(this.currentPlace.id, this.currentPlace.logoUrl);
    this.currentPlace.logoData = data;
    this.currentPlace.dirty = true;
    // this.firebaseService.uploadLogoToStorage(this.currentPlace.logoUrl, data);
  }

  private formatCoordinates(lat: number | undefined, lon: number | undefined): string {
    return formatNumbers(lat, lon);
  }

  private setId(): void {
    if (this.currentPlace.id === 0) {
      this.places.forEach((place) => {
        if (place.id! > this.currentPlace.id!) {
          this.currentPlace.id = place.id;
        }
      });
      this.currentPlace.id += 1;
    }
  }

  private setSequence(state: string): void {
    this.currentPlace.sequence = 0;
    if (state) {
      this.places?.forEach((place) => {
        if (place.state === state) {
          if (place.sequence! > this.currentPlace.sequence!) {
            this.currentPlace.sequence = place.sequence;
          }
        }
      });
      this.currentPlace.sequence += 1;
    }
  }

  private joinAddress(): void {
    setTimeout(() => {
      const street = this.currentPlace.street ?? '';
      const city = this.currentPlace.city ?? '';
      const state = placeConf.getStateAbbreviation(this.currentPlace.state!);
      const zip = this.currentPlace.zip ?? '';
      if (street !== '' && city !== '' && state !== '' && zip !== '') {
        this.currentPlace.address = `${street}, ${city}, ${state} ${zip}`;
      }
    }, 10);
  }

  private parseAddress(address: string) {
    setTimeout(() => {
      const tokens = UpdatePlaceComponent.addressTokens(address);
      this.currentPlace.street = tokens.street;
      this.currentPlace.city = tokens.city;
      const newState = placeConf.getStateName(tokens.state);
      if (
        newState !== this.currentPlace.state &&
        UpdatePlaceComponent.isAdd(this.updateType)
      ) {
        this.setSequence(newState);
      }
      this.currentPlace.state = newState;
      this.currentPlace.zip = tokens.zip;
    }, 10);
  }

  private toggleTagaCheerOny() {
    if (this.currentPlace.cheerStatus != null &&
      (this.currentPlace.tagStatus == null || this.currentPlace.tagStatus == statuses.retired)) {
      if (!this.currentPlace.name.includes('(TAGACHEER ONLY)')) {
        this.currentPlace.name = `${this.currentPlace.name} (TAGACHEER ONLY)`
      } else {
        if (this.currentPlace.name.includes('(TAGACHEER ONLY)')) {
          this.currentPlace.name = this.currentPlace.name.replace(' (TAGACHEER ONLY)', '');
        }
      }
    } else {
      if (this.currentPlace.name.includes('(TAGACHEER ONLY)')) {
        this.currentPlace.name = this.currentPlace.name.replace(' (TAGACHEER ONLY)', '');
      }
    }
  }

  // imageUrl: string | ArrayBuffer | null = null;
  // trustedUrl: any | null = null;
  //
  // onDrop($event: DragEvent) {
  //   $event.preventDefault();
  //   const file = $event.dataTransfer?.files[0];
  //   if (file && file.type === 'image/jpeg') {
  //     this.readFile(file);
  //   }
  // }
  //
  // onDragOver($event: DragEvent) {
  //   $event.preventDefault();
  // }
  //
  // onDragLeave($event: DragEvent) {
  //   $event.preventDefault();
  // }
  //
  // private readFile(file: File) {
  //   const reader = new FileReader();
  //   reader.onload = () => {
  //     this.imageUrl = reader.result;
  //     // save file to firebase and read in the url again
  //   }
  //   reader.readAsDataURL(file);
  // }
  //
  // onFileSelected($event: any) {
  //   const file: File = $event.target.files[0];
  //   const reader: FileReader = new FileReader();
  //
  //   reader.onload = () => {
  //     this.imageUrl = reader.result as string;
  //     this.trustedUrl = this.sanitizer.bypassSecurityTrustUrl(this.imageUrl);
  //     // save file to firebase and read in the url again
  //     this.firebaseService.putLogoById(999, this.trustedUrl);
  //     this.logoUrl = this.firebaseService.getLogoByUrl(this.currentPlace.logoUrl);
  //   }
  //
  //   reader.readAsText(file);
  // }
  //***
  // onFileSelected(event: any) {
  //   const selectedFile = event.target.files[0];
  //   if (this.selectedFile) {
  //     const reader = new FileReader();
  //     reader.onload = () => {
  //       // Do something with the file content, e.g., display it
  //       const result = reader.result as string;
  //       console.log(result);
  //     };
  //     reader.readAsDataURL(selectedFile);
  //   }
  // }
  //***
}
