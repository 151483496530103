/*
 * Copyright © MEFTEK LLC. All rights reserved.
 * This information is confidential and proprietary to MEFTEK LLC
 * and may not be used, modified, copied or distributed.
 */

import {Injectable, NgZone} from '@angular/core';
import {User} from '../models/user';
import auth from 'firebase/compat/app';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {AngularFirestore, AngularFirestoreDocument,} from '@angular/fire/compat/firestore';
import {Router} from '@angular/router';
import {MessageService} from 'primeng/api';
import {NGXLogger} from 'ngx-logger';


@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userData: any; // Save logged in user data

  constructor(
    public readonly afs: AngularFirestore, // Inject Firestore service
    public readonly afAuth: AngularFireAuth, // Inject Firebase auth service
    public readonly router: Router,
    public readonly ngZone: NgZone, // NgZone service to remove outside scope warning
    private readonly messageService: MessageService,
    private readonly logger: NGXLogger
  ) {
    /* Saving user data in localstorage when
    logged in and setting up null when logged out */
    this.afAuth.authState.subscribe((user) => {
      if (user) {
        this.userData = user;
        localStorage.setItem('user', JSON.stringify(this.userData));
        // JSON.parse(localStorage.getItem('user') ?? 'null');
      } else {
        localStorage.removeItem('user');
        // localStorage.setItem('user', null);
        // JSON.parse(localStorage.getItem('user') ?? 'null');
      }
    });
  }

  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    // const user = JSON.parse(localStorage.getItem('user') ?? 'null');
    const userSession = localStorage.getItem('user');
    const user = userSession ? JSON.parse(userSession) : null;
    return user !== null && user.emailVerified;
  }

  get userUid(): string {
    return this.userData?.uid ?? 'undefined';
  }

  get userDisplayName(): string {
    return this.userData.displayName
  }
  get userEmail(): string {
    return this.userData.email;
  }

  // Sign in with email/password
  SignIn(email: string, password: string) {
    return this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then((result) => {
        this.ngZone.run(() => {
          this.router.navigate(['dashboard']).then();
        });
        this.SetUserData(result.user);
      })
      .catch((error) => {
        this.messageService.add({
          key: 'bc',
          life: 3000,
          severity: 'error',
          summary: 'Error',
          detail: error.message,
        });
        // window.alert(error.message);
      });
  }

  // Send email verfificaiton when new user sign up

  // Sign up with email/password
  SignUp(email: string, password: string) {
    return this.afAuth
      .createUserWithEmailAndPassword(email, password)
      .then((result) => {
        /* Call the SendVerificationMail() function when new user sign
        up and returns promise */
        this.SendVerificationMail().then();
        this.SetUserData(result.user);
      })
      .catch((error) => {
        this.messageService.add({
          key: 'bc',
          life: 3000,
          severity: 'error',
          summary: 'Error',
          detail: error.message,
        });
        // window.alert(error.message);
      });
  }

  // SendVerificationMail() {
  //   return this.afAuth.currentUser.sendEmailVerification()
  //   .then(() => {
  //     this.router.navigate(['verify-email-address']);
  //   })
  // }

  /*
  async SendVerificationMail() {
    (await this.afAuth.currentUser).sendEmailVerification().then(() => {
        this.logger.debug('email sent');
    });
  } */

  async SendVerificationMail() {
    (await this.afAuth.currentUser)?.sendEmailVerification().then(() => {
      this.logger.debug(`Verification Email sent`);
      this.router.navigate(['verify-email-address']);
    });
  }

  // Reset Forggot password
  ForgotPassword(passwordResetEmail: string) {
    return this.afAuth
      .sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        this.messageService.add({
          key: 'bc',
          life: 3000,
          severity: 'success',
          summary: 'Password Reset',
          detail: 'Password reset email sent, check your inbox.',
        });
        // window.alert('Password reset email sent, check your inbox.');
      })
      .catch((error) => {
        this.messageService.add({
          key: 'bc',
          life: 3000,
          severity: 'error',
          summary: 'Error',
          detail: error,
        });
        // window.alert(error);
      });
  }

  // Sign in with Google
  GoogleAuth() {
    return this.AuthLogin(new auth.auth.GoogleAuthProvider());
  }

  // Sign in with Apple
  AppleAuth() {
    const provider = new auth.auth.OAuthProvider('apple.com');
    return this.AuthLogin(provider);
  }

  // Auth logic to run auth providers
  AuthLogin(provider: auth.auth.AuthProvider) {
    return this.afAuth
      .signInWithPopup(provider)
      .then((result) => {
        this.ngZone.run(() => {
          this.router.navigate(['dashboard']).then();
        });
        this.SetUserData(result.user);
      })
      .catch((error) => {
        this.messageService.add({
          key: 'bc',
          life: 3000,
          severity: 'error',
          summary: 'Error',
          detail: error,
        });
        // window.alert(error);
      });
  }

  /* Setting up user data when sign in with username/password,
  sign up with username/password and sign in with social auth
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  SetUserData(user: auth.User | null) {
    if (user !== null) {
      const userRef: AngularFirestoreDocument<any> = this.afs.doc(
        `users/${user.uid}`
      );
      const userData: User = {
        uid: user.uid,
        email: user.email ?? undefined,
        displayName: user.displayName ?? undefined,
        photoURL: user.photoURL ?? undefined,
        emailVerified: user.emailVerified,
        web: true
      };
      return userRef.set(userData, {
        merge: true,
      });
    }
    return undefined;
  }

  // Sign out
  SignOut() {
    return this.afAuth.signOut().then(() => {
      localStorage.removeItem('user');
      this.router.navigate(['sign-in']).then();
    });
  }
}
