/*
 * Copyright © MEFTEK LLC. All rights reserved.
 * This information is confidential and proprietary to MEFTEK LLC
 * and may not be used, modified, copied or distributed.
 */

import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {TableCaptionComponent} from './table-caption/table-caption.component';
import {TableSummaryComponent} from "./table-summary/table-summary.component";
import {ToolbarModule} from "primeng/toolbar";
import {ImageModule} from "primeng/image";
import {ButtonModule} from "primeng/button";
import {TooltipModule} from "primeng/tooltip";
import {MultiSelectModule} from "primeng/multiselect";
import {FormsModule} from "@angular/forms";
import {InputTextModule} from "primeng/inputtext";

@NgModule({
  declarations: [
    TableSummaryComponent,
    TableCaptionComponent
  ],
  imports: [
    CommonModule,
    ToolbarModule,
    ImageModule,
    ButtonModule,
    TooltipModule,
    MultiSelectModule,
    FormsModule,
    InputTextModule
  ],
  exports: [
    TableSummaryComponent,
    TableCaptionComponent
  ] // Export the component so other modules can use it
})
export class TableUtilsModule {}

