<!--<caption>-->
<!--  <h1>{{ title }}</h1>-->
  <p-toolbar>
    <div class="p-toolbar-group-left">
      <p-image
        *ngIf="!isMobile"
        alt="Tagabrew Logo"
        class="mr-2 lg:hide-me"
        height="40"
        src="assets/tagabrew.png"
      ></p-image>
      <!--      <span class="header-title text pb-3 pr-2">{{ title }}</span>-->
      <ng-container *ngIf="!filterDisabled">
        <!--       (onClick)="handleClearFilter(table); gf.value = ''"-->
        <p-button
          (onClick)="handleClearFilter(table);"
          [disabled]="filterDisabled || !isFiltered"
          [icon]="isFiltered ? 'pi pi-filter-slash' : 'pi pi-filter'"
          [ngStyle]="{ cursor: filterDisabled || !isFiltered ? 'not-allowed' : 'pointer' }"
          [pTooltip]="filterDisabled || !isFiltered ? '' : 'Clear Filters'"
          styleClass="p-button-info mr-2"
          tooltipPosition="right"
        ></p-button>
        <span *ngIf="!filterDisabled" class="p-input-icon-left w-full mr-2">
              <i
                class="pi pi-search"
                pTooltip="Filter Table ..."
                tooltipPosition="right"
              ></i>
              <input
                #gf
                (input)="globalFilter(table, $event)"
                [disabled]="filterDisabled"
                class="w-inherit"
                pInputText
                placeholder="Search keyword"
                type="text"
              />
        </span>
      </ng-container>
      <p-multiSelect *ngIf="!chooseColumnsDisabled"
                     [(ngModel)]="selectedColumns"
                     [disabled]="chooseColumnsDisabled"
                     [options]="columns"
                     [style]="{minWidth: '200px'}"
                     optionLabel="header"
                     placeholder="Choose Columns"
                     selectedItemsLabel="{0} columns selected"
      ></p-multiSelect>
    </div>
    <div class="p-toolbar-group-right">
      <p-button
        (onClick)="handlePlaces()"
        *ngIf="!placesDisabled"
        [disabled]="placesDisabled"
        [ngStyle]="{ cursor: placesDisabled ? 'not-allowed' : 'pointer' }"
        [pTooltip]="placesDisabled ? '' : 'Show Places'"
        icon="pi pi-building"
        styleClass="p-button-success ml-2"
        tooltipPosition="left"
      ></p-button>
      <p-button
        (onClick)="handleUsers()"
        *ngIf="!usersDisabled"
        [disabled]="usersDisabled"
        [ngStyle]="{ cursor: usersDisabled ? 'not-allowed' : 'pointer' }"
        [pTooltip]="usersDisabled ? '' : 'Show Users'"
        icon="pi pi-users"
        styleClass="ml-2"
        tooltipPosition="left"
      ></p-button>
      <p-button
        (onClick)="handleAds()"
        *ngIf="!adsDisabled"
        [disabled]="adsDisabled"
        [ngStyle]="{ cursor: adsDisabled ? 'not-allowed' : 'pointer' }"
        [pTooltip]="adsDisabled ? '' : 'Show Ads'"
        icon="pi pi-chart-bar"
        styleClass="p-button-secondary ml-2"
        tooltipPosition="left"
      ></p-button>
      <p-button
        (onClick)="handleMap()"
        *ngIf="!mapDisabled"
        [disabled]="mapDisabled"
        [ngStyle]="{ cursor: mapDisabled ? 'not-allowed' : 'pointer' }"
        [pTooltip]="mapDisabled ? '' : 'Show Map'"
        icon="pi pi-map"
        styleClass="p-button-success ml-2"
        tooltipPosition="left"
      ></p-button>
      <p-button
        (onClick)="handleAdd()"
        *ngIf="!addDisabled"
        [disabled]="addDisabled"
        [ngStyle]="{ cursor: addDisabled ? 'not-allowed' : 'pointer' }"
        [pTooltip]="addDisabled ? '' : 'Add Row...'"
        icon="pi pi-plus"
        styleClass="p-button-info ml-2"
        tooltipPosition="left"
      ></p-button>
      <p-button
        (onClick)="handleDelete()"
        *ngIf="!deleteDisabled"
        [disabled]="deleteDisabled || selectedRecords?.length === 0"
        [ngStyle]="{ cursor: deleteDisabled ? 'not-allowed' : 'pointer' }"
        [pTooltip]="deleteDisabled ? '' : 'Delete Row...'"
        icon="pi pi-trash"
        styleClass="hidden p-button-danger ml-2"
        type="button"
      ></p-button>
      <p-button
        (onClick)="handleResetStaged()"
        *ngIf="!resetStagedDisabled"
        [disabled]="resetStagedDisabled"
        [ngStyle]="{ cursor: resetStagedDisabled ? 'not-allowed' : 'pointer' }"
        [pTooltip]="resetStagedDisabled ? '' : 'Reset Staged Edits'"
        icon="pi pi-undo"
        styleClass="p-button-warning ml-2"
        tooltipPosition="left"
      ></p-button>
      <p-button
        (onClick)="handleSaveStaged()"
        *ngIf="!saveStagedDisabled"
        [badge]="stagedBadge"
        [disabled]="saveStagedDisabled"
        [ngStyle]="{ cursor: saveStagedDisabled ? 'not-allowed' : 'pointer' }"
        [pTooltip]="saveStagedDisabled ? '' : 'Save Staged Edits'"
        badgeClass="p-badge-danger"
        icon="pi pi-save"
        styleClass="p-button-success ml-2 w-4rem"
        tooltipPosition="left"
      ></p-button>
      <p-button
        (onClick)="handleCsvExport()"
        *ngIf="!exportCsvDisabled"
        [disabled]="exportCsvDisabled"
        [ngStyle]="{ cursor: resetStagedDisabled ? 'not-allowed' : 'pointer' }"
        [pTooltip]="resetStagedDisabled ? '' : 'Download CSV'"
        icon="pi pi-download"
        pTooltip="Download User List"
        styleClass="p-button-help ml-2"
        tooltipPosition="left">
      </p-button>
      <p-button
        (onClick)="authService.SignOut()"
        [disabled]="false"
        [pTooltip]="'Sign-Out'"
        icon="pi pi-sign-out"
        styleClass="p-button-danger ml-2"
        tooltipPosition="left">
      </p-button>
    </div>
  </p-toolbar>
<!--</caption>-->
