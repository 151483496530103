/*
 * Copyright © MEFTEK LLC. All rights reserved.
 * This information is confidential and proprietary to MEFTEK LLC
 * and may not be used, modified, copied or distributed.
 */

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ListComponent} from "./list.component";
import {RouterModule} from "@angular/router";
import {TooltipModule} from "primeng/tooltip";


@NgModule({
    imports: [CommonModule, RouterModule, TooltipModule],
  declarations: [ListComponent],
})
export class ListModule {}
