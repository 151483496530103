/*
 * Copyright © MEFTEK LLC. All rights reserved.
 * This information is confidential and proprietary to MEFTEK LLC
 * and may not be used, modified, copied or distributed.
 */

import { Component, OnInit } from '@angular/core';
import {StorageService} from "../../shared/services/storage.service";
import {Router} from "@angular/router";
import {FirebaseService} from "../../services/firebase.service";
import {DeviceDetectorService, DeviceInfo} from "ngx-device-detector";
import {CsvExportService} from "../../services/csv-export.service";
import * as adConf from "./ad-list.conf";
import {MessageService} from "primeng/api";
import {Table} from "primeng/table";
import {saveSort} from "../../shared/utilities";
import {Ad} from "./ad.model";
import {AuthService} from "../../shared/services/auth.service";
import {AdminService, AppPermissions, defaultAppPermissions} from "../../services/admin-service";
import {NGXLogger} from "ngx-logger";

@Component({
  selector: 'app-ad-list',
  templateUrl: './ad-list.component.html',
  styleUrls: ['./ad-list.component.scss']
})
export class AdListComponent implements OnInit {

  private deviceInfo: DeviceInfo;
  public isMobile: boolean;

  ads: Ad[] = [];
  selectedAds: Ad[] = [];
  selectedAd: Ad;
  showEditAd: boolean;

  loading: boolean = false;
  loadingBody: boolean = true;
  columns: any[] = adConf.columnDefs;
  mapsColumns: any[] = adConf.columnDefs;

  columnFiltersActive: boolean;
  globalFilterActive: boolean;
  sortActive: boolean;

  sortField: string = adConf.defaultSortField;
  sortOrder: number = adConf.defaultSortOrder;
  filters: any = {};

  adminPermissions: AppPermissions = defaultAppPermissions;

  initialized: boolean;

  clonedAds: { [s: string]: Ad; } = {};

  constructor(
    public readonly authService: AuthService,
    private readonly storageService: StorageService,
    private readonly router: Router,
    private readonly firebaseService: FirebaseService,
    private readonly deviceService: DeviceDetectorService,
    private readonly csvExportService: CsvExportService,
    private readonly adminService: AdminService,
    private readonly logger: NGXLogger,
    private readonly messageService: MessageService,
  ) {
    this.deviceInfo = deviceService.getDeviceInfo()
  }

  ngOnInit(): void {
    this.getAdminPermissions();
    this.getAdsList();

    this.isMobile = this.deviceService.isMobile();

    // Retrieve settings from storage on initialization
    const savedFilters = this.storageService.getItem(StorageService.adStatsFilter);
    const savedSortField = this.storageService.getItem(StorageService.adStatsSortField);
    const savedSortOrder = this.storageService.getItem(StorageService.adStatsSortOrder);

    if (savedSortField) {
      this.sortField = savedSortField;
    }
    if (savedSortOrder) {
      this.sortOrder = savedSortOrder;
    }
    if (savedFilters) {
      this.filters = savedFilters;
    }

    setTimeout(() => {
      this.initialized = true;
    },1000);
  }

  private getAdsList() {
    this.firebaseService.getAds().subscribe({
      next: data => {
        this.ads = data.map(ad => {
          return {
            ...ad,
            campaignEnd: this.parseDateWithMidnight(ad.campaign.end),
            campaignStart: this.parseDateWithMidnight(ad.campaign.start),
            coordinatesLat: ad.coordinates.lat,
            coordinatesLng: ad.coordinates.lng,
            statisticsClicked: ad.statistics.clicked,
            statisticsDisplayed: ad.statistics.displayed,
          }

        })
        this.messageService.add({
          key: 'bc',
          life: 3000,
          severity: 'success',
          summary: 'Success',
          detail: 'Adverisements Successfully Loaded.',
        });
      },
      error: err => {
        const errMsg = `Advertisements Load Failed, ${err}`;
        this.logger.error(errMsg);
        this.loading = false;
        this.messageService.add({
          key: 'bc',
          life: 3000,
          severity: 'error',
          summary: 'Error',
          detail: errMsg,
        });
      }
    })
  }

  /**
   * Get admin properties from database
   * @private
   */
  private getAdminPermissions(): void {
    this.adminService.getAppPermissions()
      .then((appPermissions) => {
        if (appPermissions) {
          this.adminPermissions = appPermissions;
          this.logger.info('Admin App Permissions:', appPermissions);
        }
      }).catch((err) => {
      const errMsg = `Error getting admin permissions, edits are disabled, ${err}`;
      this.logger.error(errMsg);
      this.messageService.add({
        key: 'bc',
        life: 3000,
        severity: 'error',
        summary: 'Error',
        detail: errMsg,
      });
    });
  }

  parseDateWithMidnight(dateString: string): Date {
    // Split the string into year, month, and day
    const [year, month, day] = dateString.split('-').map(Number);
    // Create a new Date object with the time set to midnight local time
    return new Date(year, month - 1, day);
  }

  isDateColumn(field: string): boolean {
    const columns = [
      'campaignEnd',
      'campaignStart',
    ]
    return columns.includes(field);
  }

  isNumericColumn(field: string): boolean {
    const columns = [
      'coordinatesLat',
      'coordinatesLng',
      'id',
      'radius',
      'statisticsClicked',
      'statisticsDisplayed',
    ];
    return columns.includes(field);
  }

  /**
   * Returns true if user can view
   */
  get canView(): boolean {
    return this.adminPermissions.canView?.includes(this.authService.userUid);
  }

  /**
   * Returns true if places are filtered
   */
  get isFiltered(): boolean {
    return this.globalFilterActive || this.columnFiltersActive || this.sortActive;
  }

  /**
   * Globally filter the table by $event
   * @param table The table to filter
   * @param $event The value to filter by (contains)
   */
  globalFilter(table: Table, $event: any): void {
    table.filterGlobal($event.target.value, 'contains');
    this.globalFilterActive = $event.target.value.length > 0;
  }

  /**
   * Update filter when entering filter text for table
   * @param $event The event data for the filter
   */
  handleFilter($event: any) {
    let columnFiltersActive = false;
    (Object.keys($event.filters) as (keyof typeof $event.filters)[]).forEach((key, _index) => {
      if ($event.filters[key].length > 1 || $event.filters[key][0]?.value != null) {
        columnFiltersActive = true;
      }
    });
    this.columnFiltersActive = columnFiltersActive;
  }

  handleClearFilter(table: Table) {
    this.globalFilter(table, { target: { value: '' } });
    table.reset();
    table.sortField = adConf.defaultSortField;
    table.sortOrder = adConf.defaultSortOrder;
    table.sortSingle();
    // this.selectedColumns = this.isMobile ? placeConf.mobileColumnDefs : placeConf.columnDefs;
  }

  handleSort($event: any) {
    this.sortActive = saveSort(
      this.storageService,
      adConf.defaultSortField,
      adConf.defaultSortOrder,
      StorageService.adStatsSortField,
      StorageService.adStatsSortOrder,
      $event
    );
  }

  handleDelete() {
    alert("Delete Not Implemented.")
  }

  handlePlaces() {
    this.router.navigate(['/dashboard']).then();
  }

  handleUsers() {
    this.router.navigate(['/user-profile']).then();
  }

  handleAdd() {
    this.messageService.add({
      key: 'bc',
      life: 3000,
      severity: 'warn',
      summary: 'Warning',
      detail: 'Not implemented.',
    });
  }

  /**
   * Handle the csv export button click
   */
  handleCsvExport() {
    this.csvExportService.exportCsvFile(this.ads, this.mapsColumns, 'TagaBrew Advertisement List').then();
  }

  // onRowEditInit(ad: Ad) {
  //   this.clonedAds[ad.documentId] = {...ad};
  // }

  // onRowEditSave(ad: Ad) {
  //     delete this.clonedAds[ad.documentId];
  //     ad = {
  //       ...ad,
  //       campaign: {
  //         end: ad.campaignEnd.toISOString().split('T')[0],
  //         start: ad.campaignStart.toISOString().split('T')[0],
  //       },
  //       coordinates: {
  //         lat: ad.coordinatesLat,
  //         lng: ad.coordinatesLng,
  //       },
  //       statistics: {
  //         clicked: ad.statisticsClicked,
  //         displayed: ad.statisticsDisplayed,
  //       }
  //     }
  //     console.log(JSON.stringify(ad));
  //     this.messageService.add({severity:'success', summary: 'Success', detail:'Ad is updated'});
  // }

  // onRowEditCancel(ad: Ad, index: number) {
  //   this.ads[index] = this.clonedAds[ad.documentId];
  //   delete this.clonedAds[ad.documentId];
  // }

  onAdEdit(ad: Ad) {
    this.selectedAd = ad;
    this.showEditAd = true;
  }

  // callFunction() {
  //   this.firebaseService.checkUserDisabled('XXX4hyarmkR6tPafETCEARSR8rFRaO2')
  //     .then(result => {
  //       console.log('Function result:', result);
  //     })
  //     .catch(error => {
  //       // Check if the error.message is a JSON string
  //       try {
  //         const errorData = JSON.parse(error.message);
  //         console.error('Error code:', errorData.code);
  //         console.error('Error message:', errorData.message);
  //       } catch (parseError) {
  //         // Handle cases where error.message is not valid JSON
  //         console.error('Error calling function, Error parsing error message:', error.message);
  //       }
  //     });
  // }

}
