<ng-container *ngIf="!showPage; else displayList">
<!--  <div class="flex justify-content-between m-3">-->
<!--    <span class="text-lg font-bold">{{state}} TagaBrew Trail</span>-->
<!--  </div>-->
<!--  <div class="get-moving-text text-lg">{{getMovingText}}</div>-->
</ng-container>
<ng-template #displayList>
  <div [style]="{'width': '100%','height': 'calc(100vh - 57px)'}" class="card text-lg ml-6">
    <ol class="justify-content-center">
      <ng-container *ngFor="let place of places">
        <li *ngIf="isNotRetiredOrHidden(place)">
        <span>
          <span *ngIf="place.websiteURL">
            <a [href]="place.websiteURL" target="_blank">{{place.name}}</a>  / {{place.city}}</span>
          <span *ngIf="!place.websiteURL">{{place.name}} / {{place.city}}</span>
        </span>
          <span *ngIf="hasCharm(place)" pTooltip="Charm" style="color: blue;">
          ({{place.charmDesc}})
        </span>
          <span *ngIf="isSoon(place)" class="font-bold" pTooltip="Coming Soon" style="color: goldenrod;">
          &quot;SOON&quot;
        </span>
          <span class="font-bold" pTooltip="Has TagaCheer" style="color: rebeccapurple;">
          {{isCheer(place) ? 'CHEER' : ''}}
        </span>
        </li>
      </ng-container>
    </ol>
  </div>
</ng-template>
