<!--
  ~ Copyright © MEFTEK LLC. All rights reserved.
  ~ This information is confidential and proprietary to MEFTEK LLC
  ~ and may not be used, modified, copied or distributed.
  -->

<div class="flex justify-content-between m-3">
  <span class="text-lg font-bold">{{state}} TagaBrew Trail</span>
  <span *ngIf="showGoBack" class="text-lg"><a routerLink="/dashboard">Go Back</a></span>
</div>
<ng-container *ngIf="!showPage; else displayMap">
<!--  <div class="get-moving-text text-lg">{{getMovingText}}</div>-->
</ng-container>
<ng-template #displayMap>
  <div [style]="{'width': '100%','height': 'calc(100vh - 57px)'}" class="card">
    <p-gmap
      (onMapReady)="onMapReady($event)"
      [options]="options"
      [overlays]="overlays"
      styleClass="h-full"
    ></p-gmap>
  </div>
</ng-template>
