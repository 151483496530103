import { Directive } from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator} from "@angular/forms";

@Directive({
  selector: '[appCoordinates]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: CoordinatesDirective,
    multi: true
  }]
})
export class CoordinatesDirective implements Validator{
  validate(control: AbstractControl) : {[key: string]: any} | null {
    const regEx = new RegExp('^-*\\d+\\.\\d{2,}\,-*\\d+\\.\\d{2,}$');
    if (control.value && !regEx.test(control.value)) {
      return { 'coordinatesInvalid': true };
    }
    return null;
  }
}
