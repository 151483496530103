/*
 * Copyright © MEFTEK LLC. All rights reserved.
 * This information is confidential and proprietary to MEFTEK LLC
 * and may not be used, modified, copied or distributed.
 */

// src/app/admin.service.ts
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { firstValueFrom } from 'rxjs';
import {NGXLogger} from "ngx-logger";

export interface AppPermissions {
  canEdit: string;
  canSave: string;
  canStage: string;
  canView: string;
}

export const defaultAppPermissions: AppPermissions = {
  canEdit: "",
  canSave: '',
  canStage: '',
  canView: '',
};

export interface EmailAddresses {
  toEmailsProd: string;
  toEmailsStage: string;
}

export const defaultEmailAddresses: EmailAddresses = {
  toEmailsStage: 'meftek@gmail.com',
  toEmailsProd: 'meftek@gmail.com'
};

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  private readonly appPermissionsDoc = this.firestore.doc<AppPermissions>('admin/app-permissions');
  private readonly emailAddressesDoc = this.firestore.doc<EmailAddresses>('admin/email-addresses');

  constructor(
    private firestore: AngularFirestore,
    private readonly logger: NGXLogger,
    ) {}

  async getAppPermissions(): Promise<AppPermissions> {
    const docSnapshot = await firstValueFrom(this.appPermissionsDoc.valueChanges());
    if (!docSnapshot) {
      this.logger.error('App Permissions document does not exist');
      return defaultAppPermissions;
    }
    return docSnapshot;
  }

  async getEmailAddresses(): Promise<EmailAddresses> {
    const docSnapshot = await firstValueFrom(this.emailAddressesDoc.valueChanges());
    if (!docSnapshot) {
      this.logger.error('Email Addresses document does not exist');
      return defaultEmailAddresses;
    }
    return docSnapshot;
  }
}
