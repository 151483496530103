<!--
  ~ Copyright © MEFTEK LLC. All rights reserved.
  ~ This information is confidential and proprietary to MEFTEK LLC
  ~ and may not be used, modified, copied or distributed.
  -->

<p-toast></p-toast>
<div class="displayTable">
  <div class="displayTableCell">
    <div class="tagabrew-logo">
      <a href="https://www.tagabrew.com" target="_blank">
        <img
          alt="TagaBrew.com - Tag your brewery trail"
          src="assets/tagabrew.png"
        />
      </a>
      <div class="redirectToLogin">Database Editor</div>
    </div>

    <div class="authBlock">
      <h3>Sign In</h3>
      <!-- Calling AppleAuth Api from AuthService -->
<!--      <div class="formGroup">-->
<!--        <p-button-->
<!--          (onClick)="authService.AppleAuth()"-->
<!--          styleClass="btn appleBtn p-button-outlined"-->
<!--        >-->
<!--          <img alt="apple logo" class="appleLogo" src="assets/apple-logo.svg"/>-->
<!--          <span class="p-ml-2 p-text-bold">Sign in with Apple</span>-->
<!--        </p-button>-->
<!--      </div>-->

      <!-- Calling GoogleAuth Api from AuthService -->
      <div class="formGroup">
        <p-button
          (onClick)="authService.GoogleAuth()"
          styleClass="btn googleBtn p-button-outlined"
        >
          <img alt="google logo" class="googleLogo" src="assets/google.svg"/>
          <span class="p-ml-2 p-text-bold">Sign in with Google</span>
        </p-button>
      </div>

<!--      <div class="formGroup">-->
<!--        <span class="or"><span class="orInner">Or</span></span>-->
<!--      </div>-->

<!--      <div class="formGroup">-->
<!--        <input-->
<!--          #userName-->
<!--          class="formControl"-->
<!--          placeholder="Username"-->
<!--          required-->
<!--          type="text"-->
<!--        />-->
<!--      </div>-->

<!--      <div class="formGroup">-->
<!--        <input-->
<!--          #userPassword-->
<!--          class="formControl"-->
<!--          placeholder="Password"-->
<!--          required-->
<!--          type="password"-->
<!--        />-->
<!--      </div>-->

<!--      &lt;!&ndash; Calling SignIn Api from AuthService &ndash;&gt;-->
<!--      <div class="formGroup">-->
<!--        <p-button-->
<!--          (onClick)="authService.SignIn(userName.value, userPassword.value)"-->
<!--          styleClass="btn emailBtn p-button-outlined"-->
<!--        >-->
<!--          <img alt="email logo" class="emailLogo" src="assets/mail.svg"/>-->
<!--          <span class="ml-2 font-bold">Sign in with Email</span>-->
<!--        </p-button>-->
<!--      </div>-->

      <div class="forgotPassword">
        <span routerLink="/forgot-password">Forgot Password?</span>
      </div>
    </div>

    <div class="redirectToLogin">
      <span
      >Don't have an account?
        <span class="redirect mx-3" routerLink="/register-user"> Sign Up</span>
        <span>v{{ appVersion }}{{ dbEnv }}</span>
      </span>
    </div>
  </div>
</div>
