/*
 * Copyright © MEFTEK LLC. All rights reserved.
 * This information is confidential and proprietary to MEFTEK LLC
 * and may not be used, modified, copied or distributed.
 */

import {Component, Input, OnInit} from '@angular/core';
import {initialVersion, Version} from "../../../models/version.model";
import {environment} from "../../../../environments/environment";
import {EnvTypeEnum, envTypeIsProd} from "../../../models/env-type.enum";
import {FirebaseService} from "../../../services/firebase.service";
import {catchError, firstValueFrom, Observable, of, tap} from "rxjs";
import {NGXLogger} from "ngx-logger";
import {MessageService} from "primeng/api";
import {AuthService} from "../../../shared/services/auth.service";
import {version} from "@typescript-eslint/parser";

@Component({
  selector: 'app-table-summary',
  templateUrl: './table-summary.component.html',
  styleUrls: ['./table-summary.component.scss']
})
export class TableSummaryComponent implements OnInit {
  @Input() hideLegend: boolean;

  appVersion: string;
  dbEnv: string;
  stageVersion: Version = initialVersion;
  prodVersion: Version = initialVersion;

  constructor(
    public readonly authService: AuthService,
    private readonly firebaseService: FirebaseService,
    private readonly logger: NGXLogger,
    private readonly messageService: MessageService,
  ) {
  }

  ngOnInit(): void {
    this.appVersion = environment.app.version;
    this.dbEnv = environment.app.dbEnv;
    this.getVersionProd().subscribe(version => {
      if (version) {
        console.log('Current Prod version:', version);
        this.prodVersion = version;
      }
    });
    this.getVersionStage().subscribe(version => {
      if (version) {
        console.log('Current stage version:', version);
        this.stageVersion = version;
      }
    });
  }

  /**
   * Get the version of the database
   * @param envType The environment to get from (STAGE or PROD)
   * @private
   */
  private getVersion(envType: EnvTypeEnum): Observable<null | Version> {
      return this.firebaseService.getVersion(envType).valueChanges().pipe(
        tap((version) => {
          this.logger.info(`Version (${envType}):`, version);
        }),
        catchError((err) => {
          const errMsg = `Error getting (${envType}) version, ${err}`;
          this.logger.error(errMsg);
          this.messageService.add({
            key: 'bc',
            life: 3000,
            severity: 'error',
            summary: 'Error',
            detail: errMsg,
          });
          return of(null);
        })
      );
  }

  /**
   * Get version of the PROD database
   * @private
   */
  private getVersionProd(): Observable<Version | null> {
    return this.getVersion(EnvTypeEnum.PROD);
  }

  /**
   * Get version of the STAGE database
   * @private
   */
  private getVersionStage(): Observable<Version | null> {
    return this.getVersion(EnvTypeEnum.STAGE);
  }
}

