import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  public dark: boolean;

  constructor( private readonly route: ActivatedRoute,) { }

  ngOnInit(): void {
    this.dark = this.route.snapshot.params['darkMode'] === 'dark';

  }

}
