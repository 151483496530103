/*
 * Copyright © MEFTEK LLC. All rights reserved.
 * This information is confidential and proprietary to MEFTEK LLC
 * and may not be used, modified, copied or distributed.
 */

export enum EnvTypeEnum {
  STAGE = 'STAGE',
  PROD = 'PROD',
}

export function envTypeIsProd(envType: EnvTypeEnum) {
  return envType === EnvTypeEnum.PROD;
}

export function envTypeIsStage(envType: EnvTypeEnum) {
  return envType === EnvTypeEnum.STAGE;
}
