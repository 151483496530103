/*
 * Copyright © MEFTEK LLC. All rights reserved.
 * This information is confidential and proprietary to MEFTEK LLC
 * and may not be used, modified, copied or distributed.
 */

import { Component, OnInit } from '@angular/core';
import {FirebaseService} from "../../../services/firebase.service";
import {MessageService} from "primeng/api";
import {NGXLogger} from "ngx-logger";
import {ActivatedRoute, Router} from "@angular/router";
import {getStateName} from "../../place/place.conf";
import {BaseMapListComponent} from "../../base-map-list/base-map-list.component";

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent extends BaseMapListComponent implements OnInit {

  constructor(
    protected readonly firebaseService: FirebaseService,
    protected readonly messageService: MessageService,
    protected readonly logger: NGXLogger,
    protected route: ActivatedRoute,
    protected router: Router,
  ) {
    super(firebaseService, messageService, logger, route);
  }

  ngOnInit(): void {
    this.stateParam = this.route.snapshot.params['state'];
    this.state = getStateName(this.stateParam);
    if (this.state?.toLowerCase() !== 'usa') {
      this.getPlacesProd(this.state);
    } else {
      this.router.navigate(['/404']).then();
    }
  }
}
