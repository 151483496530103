/*
 * Copyright © MEFTEK LLC. All rights reserved.
 * This information is confidential and proprietary to MEFTEK LLC
 * and may not be used, modified, copied or distributed.
 */

import {Component, OnInit} from '@angular/core';
import {PrimeNGConfig} from 'primeng/api';
import {DomHandler} from "primeng/dom";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'TagaBrew Web App';

  constructor(private primengConfig: PrimeNGConfig) {}

  ngOnInit() {
    this.primengConfig.ripple = true;
    DomHandler.addClass(document.body, 'p-input-filled');
  }
}
