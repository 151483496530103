/*
 * Copyright © MEFTEK LLC. All rights reserved.
 * This information is confidential and proprietary to MEFTEK LLC
 * and may not be used, modified, copied or distributed.
 */

import {SelectItem} from "primeng/api";

export const defaultSortField = 'name';
export const defaultSortOrder = 1;

export const columnDefs = [
  { field: 'id', header: 'id', index: 0, width: '5.5rem' },
  { field: 'name', header: 'Name', index: 1, width: '20rem' },
  { field: 'documentId', header: 'Document ID', index: 2, width: '15rem' },
  { field: 'campaignStart', header: 'Campaign Start', index: 3, width: '12rem' },
  { field: 'campaignEnd', header: 'Campaign End', index: 4, width: '12rem' },
  { field: 'coordinatesLat', header: 'Coordinates Lat', index: 5, width: '12rem' },
  { field: 'coordinatesLng', header: 'Coordinates Lng', index: 6, width: '12rem' },
  { field: 'radius', header: 'Radius', index: 7, width: '8rem' },
  { field: 'statisticsClicked', header: 'Statistics Clicked', index: 8, width: '14rem' },
  { field: 'statisticsDisplayed', header: 'Statistics Displayed', index: 9, width: '14rem' },
  { field: 'detailsUrl', header: 'Details Url', index: 10, width: '15rem' },
  { field: 'storageUrl', header: 'Storage Url', index: 11, width: '15rem' },
];

export const mobileColumnDefs = [
  { field: 'id', header: 'id', index: 0, width: '5.5rem' },
  { field: 'name', header: 'Name', index: 1, width: '20rem' },
  { field: 'documentId', header: 'Document ID', index: 2, width: '15rem' },
  { field: 'campaignStart', header: 'Campaign Start', index: 3, width: '12rem' },
  { field: 'campaignEnd', header: 'Campaign End', index: 4, width: '12rem' },
  { field: 'coordinatesLat', header: 'Coordinates Lat', index: 5, width: '12rem' },
  { field: 'coordinatesLng', header: 'Coordinates Lng', index: 6, width: '12rem' },
  { field: 'radius', header: 'Radius', index: 7, width: '8rem' },
  { field: 'statisticsClicked', header: 'Statistics Clicked', index: 8, width: '14rem' },
  { field: 'statisticsDisplayed', header: 'Statistics Displayed', index: 9, width: '14rem' },
  { field: 'detailsUrl', header: 'Details Url', index: 10, width: '15rem' },
  { field: 'storageUrl', header: 'Storage Url', index: 11, width: '15rem' },
];

export const yesNoTFDD: SelectItem[] = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
  { label: '---', value: undefined },
];
