<!--
  ~ Copyright © MEFTEK LLC. All rights reserved.
  ~ This information is confidential and proprietary to MEFTEK LLC
  ~ and may not be used, modified, copied or distributed.
  -->

<p-toast key="bc" position="bottom-center"></p-toast>

<ng-container *ngIf="initialized && !canView">
  <h1 class="blink_me" style="color: red;">You must be authorized by the site admin to use this site.</h1>
  <h3 *ngIf="authService.userData as user">You are logged in with email: {{ user.email }}<br><br>Contact the site
    admin.</h3>
  <div class="flex justify-content-center text-sm mt-2 sm:mt-0">
    <a (click)="authService.SignOut()" class="nav-link underline">
      <i class="pi pi-sign-out"></i>&nbsp;Sign out
    </a>
  </div>
</ng-container>
<ng-container *ngIf="canView">
  <div class="px-2">
    <p-table
      #userStatsTable
      (onFilter)="handleFilter($event)"
      (onSort)="handleSort($event)"
      [(selection)]="selectedAds"
      [columns]="columns"
      [filters]="filters"
      [globalFilterFields]="[
      'displayName',
      'email',
      'uid',
    ]"
      [loading]="loading"
      [paginator]="true"
      [reorderableColumns]="true"
      [resizableColumns]="true"
      [responsive]="true"
      [rowHover]="true"
      [rowsPerPageOptions]="[10, 25, 50, { showAll: 'All' }]"
      [rows]="10"
      [scrollHeight]="'flex'"
      [scrollable]="true"
      [showCurrentPageReport]="true"
      [showLoader]="false"
      [sortField]="sortField"
      [sortOrder]="sortOrder"
      [value]="ads"
      breakpoint="680px"
      columnResizeMode="expand"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
      dataKey="id"
      loadingIcon="pi pi-spin pi-spinner loading-icon-size"
      responsiveLayout="stack"
      scrollDirection="both"
      sortMode="single"
      styleClass="p-datatable-sm p-datatable-gridlines tb-table px-2 pb-2"
    >
      <!-- CAPTION -->
      <ng-template pTemplate="caption">
<!--        <app-table-caption></app-table-caption>-->
        <p-toolbar>
          <div class="p-toolbar-group-left">
            <p-image
              *ngIf="!isMobile"
              alt="Tagabrew Logo"
              class="mr-2 lg:hide-me"
              height="40"
              src="assets/tagabrew.png"
            ></p-image>

            <p-button
              (onClick)="handleClearFilter(userStatsTable); gf.value = ''"
              [disabled]="!isFiltered"
              [icon]="isFiltered ? 'pi pi-filter-slash' : 'pi pi-filter'"
              [ngStyle]="{ cursor: isFiltered ? 'pointer' : 'not-allowed' }"
              [pTooltip]="isFiltered ? 'Clear Filters' : ''"
              styleClass="p-button-info mr-2"
              tooltipPosition="right"
            ></p-button>
            <span class="p-input-icon-left w-full mr-2">
            <i
              class="pi pi-search"
              pTooltip="Filter Table ..."
              tooltipPosition="right"
            ></i>
            <input
              #gf
              (input)="globalFilter(userStatsTable, $event)"
              [disabled]="false"
              class="w-inherit"
              pInputText
              placeholder="Search keyword"
              type="text"
            />
          </span>
          </div>
          <div class="p-toolbar-group-right">
            <p-button
              (onClick)="handlePlaces()"
              [disabled]="false"
              [ngStyle]="{ cursor: 'pointer' }"
              icon="pi pi-building"
              pTooltip="Show Places"
              styleClass="p-button-success ml-2"
              tooltipPosition="left"
            ></p-button>
            <p-button
              (onClick)="handleUsers()"
              [disabled]="false"
              [ngStyle]="{ cursor: 'pointer' }"
              icon="pi pi-users"
              pTooltip="Show User Profiles"
              styleClass="ml-2"
              tooltipPosition="left"
            ></p-button>
            <p-button
              [disabled]="true"
              [ngStyle]="{ cursor: 'not-allowed' }"
              icon="pi pi-chart-bar"
              styleClass="ml-2"
            ></p-button>
            <p-button
              (onClick)="handleAdd()"
              [disabled]="false"
              [ngStyle]="{ cursor: 'pointer' }"
              [pTooltip]="'Add New Ad'"
              icon="pi pi-plus"
              styleClass="p-button-info ml-2"
              tooltipPosition="left"
            ></p-button>
            <p-button
              (onClick)="handleDelete()"
              [disabled]="selectedAds?.length === 0"
              icon="pi pi-trash"
              styleClass="hidden p-button-danger ml-2"
              type="button"
            ></p-button>
            <p-button
              (onClick)="handleCsvExport()"
              [disabled]="false"
              icon="pi pi-download"
              pTooltip="Download Ads List"
              styleClass="p-button-help ml-2"
              tooltipPosition="left">
            </p-button>
            <p-button
              (onClick)="authService.SignOut()"
              [disabled]="false"
              [pTooltip]="'Sign-Out'"
              icon="pi pi-sign-out"
              styleClass="p-button-danger ml-2"
              tooltipPosition="left">
            </p-button>
          </div>
        </p-toolbar>
      </ng-template>

      <!-- HEADER -->
      <ng-template let-columns pTemplate="header">
        <tr>
          <th style="width: 3rem;">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th
            *ngFor="let col of columns"
            [pSortableColumn]="col.field"
            [style.width]="col.width"
            pReorderableColumn
            pResizableColumn
          >
            <div [ngSwitch]="true" class="p-d-flex p-jc-between p-ai-center">
              {{ col.header }}
              <p-sortIcon [field]="col.field"></p-sortIcon>
              <!-- date -->
              <p-columnFilter
                *ngSwitchCase="isDateColumn(col.field)"
                [field]="col.field"
                display="menu"
                type="date"
              ></p-columnFilter>
              <!-- numeric -->
              <p-columnFilter
                *ngSwitchCase="isNumericColumn(col.field)"
                [field]="col.field"
                display="menu"
                type="numeric"
              ></p-columnFilter>
              <!-- default/text -->
              <p-columnFilter
                *ngSwitchDefault
                [field]="col.field"
                display="menu"
                matchMode="contains"
                type="text"
              ></p-columnFilter>
            </div>
          </th>
<!--          <th -->
<!--            [frozen]="true" -->
<!--            alignFrozen="right" -->
<!--            class="w-8rem" -->
<!--            pFrozenColumn-->
<!--          ></th>-->
        </tr>

        <!-- LOADING -->
        <tr *ngIf="loading">
          <td class="w-screen" colspan="22">
            <p-progressBar
              [style]="{ height: '0.4rem' }"
              mode="indeterminate"
            ></p-progressBar>
          </td>
        </tr>
      </ng-template>

      <!-- LOADING BODY -->
      <ng-template pTemplate="loadingbody">
        <ng-container *ngIf="loading && loadingBody && !ads?.length">
          <tr *ngFor="let _ of [1, 2, 3, 4, 5]" class="loading-height">
            <td
              *ngFor="let column of columns"
              [ngStyle]="{ flex: '0 0 ' + column.width }"
            >
              <div class="loading-flex">
                <p-skeleton class="w-full"></p-skeleton>
              </div>
            </td>
          </tr>
        </ng-container>
      </ng-template>

      <!-- BODY -->
      <ng-template let-ad let-columns="columns" pTemplate="body">
        <tr>
          <td style="width: 3rem;">
            <p-tableCheckbox [value]="ad"></p-tableCheckbox>
          </td>
          <td
            *ngFor="let col of columns"
            [style.width]="col.width"
            pReorderableColumn
            pResizableColumn
          >
            <ng-container [ngSwitch]="true">
              <ng-container *ngSwitchCase="isDateColumn(col.field)">
                {{ ad[col.field] | date: "yyyy-MM-dd" }}
              </ng-container>
              <!-- default/string -->
              <ng-container *ngSwitchDefault>
                {{ ad[col.field] ?? '---' }}
              </ng-container>
            </ng-container>
          </td>
<!--          <td-->
<!--            [frozen]="true"-->
<!--            alignFrozen="right"-->
<!--            class="w-8rem text-center"-->
<!--            pFrozenColumn-->
<!--          >-->
<!--            <button-->
<!--              (click)="onAdEdit(ad)"-->
<!--              pTooltip="Edit Ad ..."-->
<!--              class="p-button-rounded p-button-text"-->
<!--              icon="pi pi-pencil"-->
<!--              pButton-->
<!--              pRipple-->
<!--              tooltipPosition="left"-->
<!--              type="button"-->
<!--            ></button>-->
<!--&lt;!&ndash;            <button&ndash;&gt;-->
<!--&lt;!&ndash;              *ngIf="editing"&ndash;&gt;-->
<!--&lt;!&ndash;              (click)="onRowEditSave(ads)"&ndash;&gt;-->
<!--&lt;!&ndash;              [ngStyle]="{cursor: 'pointer'}"&ndash;&gt;-->
<!--&lt;!&ndash;              pTooltip="Save Edit ..."&ndash;&gt;-->
<!--&lt;!&ndash;              class="p-button-rounded p-button-text p-button-success mr-2"&ndash;&gt;-->
<!--&lt;!&ndash;              icon="pi pi-check"&ndash;&gt;-->
<!--&lt;!&ndash;              pButton&ndash;&gt;-->
<!--&lt;!&ndash;              pRipple&ndash;&gt;-->
<!--&lt;!&ndash;              pSaveEditableRow&ndash;&gt;-->
<!--&lt;!&ndash;              tooltipPosition="left"&ndash;&gt;-->
<!--&lt;!&ndash;              type="button"&ndash;&gt;-->
<!--&lt;!&ndash;            ></button>&ndash;&gt;-->
<!--&lt;!&ndash;            <button&ndash;&gt;-->
<!--&lt;!&ndash;              *ngIf="editing"&ndash;&gt;-->
<!--&lt;!&ndash;              (click)="onRowEditCancel(ads, ri)"&ndash;&gt;-->
<!--&lt;!&ndash;              [ngStyle]="{cursor: 'pointer'}"&ndash;&gt;-->
<!--&lt;!&ndash;              pTooltip="Cancel Edit ..."&ndash;&gt;-->
<!--&lt;!&ndash;              class="p-button-rounded p-button-text p-button-danger"&ndash;&gt;-->
<!--&lt;!&ndash;              icon="pi pi-times"&ndash;&gt;-->
<!--&lt;!&ndash;              pButton&ndash;&gt;-->
<!--&lt;!&ndash;              pRipple&ndash;&gt;-->
<!--&lt;!&ndash;              pCancelEditableRow&ndash;&gt;-->
<!--&lt;!&ndash;              tooltipPosition="left"&ndash;&gt;-->
<!--&lt;!&ndash;              type="button"&ndash;&gt;-->
<!--&lt;!&ndash;            ></button>&ndash;&gt;-->
<!--          </td>-->
        </tr>
      </ng-template>

      <!-- SUMMARY -->
      <ng-template pTemplate="summary">
        <app-table-summary [hideLegend]="true"></app-table-summary>
      </ng-template>
    </p-table>
  </div>
<!--</div>-->
</ng-container>

<app-ad-edit
  [(visible)]="showEditAd"
  [ad]="selectedAd"
></app-ad-edit>
