import {Component} from '@angular/core';
import {Place, statuses} from "../place/place.model";
import {FirebaseService} from "../../services/firebase.service";
import {MessageService} from "primeng/api";
import {NGXLogger} from "ngx-logger";
import {ActivatedRoute} from "@angular/router";
import {firstValueFrom} from "rxjs";

@Component({
  selector: 'app-base-map-list',
  template: `
    <p>
      base works!
    </p>
  `,
  styles: [
  ]
})
export class BaseMapListComponent {
  public stateParam: string;
  public state: string;
  public places: Place[] = [];
  public loading = false;
  public getMovingText = 'BETTER GET MOVING. NO TAGABREW / TAGACHEER IN THIS STATE!'

  public get isValidState(): boolean {
    return !!this.state?.length
  }

  public get showPage(): boolean {
    return !!this.state?.length && !!this.places?.length;
  }

  constructor(
    protected readonly firebaseService: FirebaseService,
    protected readonly messageService: MessageService,
    protected readonly logger: NGXLogger,
    protected route: ActivatedRoute,
  ) {}

  /**
   * Get array of place objects from PROD database
   * @protected
   */
  protected getPlacesProd(state: string): void {
    this.loading = true;
    firstValueFrom(this.firebaseService.getPlacesProd(state))
      .then((places) => {
        this.places = places;
        // this.showMap();
        this.loading = false;
        this.messageService.add({
          key: 'bc',
          life: 3000,
          severity: 'success',
          summary: 'Success',
          detail: 'Database Successfully Loaded',
        });
      })
      .catch((err) => {
        const errMsg = `Database Load Failed, ${err}`;
        this.logger.error(errMsg);
        this.messageService.add({
          key: 'bc',
          life: 3000,
          severity: 'error',
          summary: 'Error',
          detail: `Database Load Failed, ${errMsg}`,
        });
      });
  }

  public isNotRetiredOrHidden(place: Place): boolean {
    return place.tagStatus !== statuses.hidden && place.tagStatus !== statuses.retired;
  }

  public isSoon(place: Place): boolean {
    return place.tagStatus === statuses.soon;
  }

  public isActive(place: Place): boolean {
    return place.tagStatus === statuses.active;
  }

  public isCheer(place: Place): boolean {
    return place.cheer === statuses.yes;
  }

  public hasCharm(place: Place): boolean {
    return place.charm === statuses.active;
  }
}
