<!--<p-fileUpload [auto]="true" mode="basic" name="file" url="your-upload-url" (uploadHandler)="handleUpload($event)" [customUpload]="true" accept="image/*"></p-fileUpload>-->
<!--<img *ngIf="imageDataUrl" [src]="imageDataUrl" alt="Uploaded Image" height="200px" width="200px">-->
<!--<p-fileUpload-->
<!--  name="file"-->
<!--  url="your-upload-url"-->
<!--  (uploadHandler)="handleUpload($event)"-->
<!--  [customUpload]="true"-->
<!--  accept="image/*"-->
<!--  [auto]="true"-->
<!--  [chooseLabel]="'Select Image'"-->
<!--  [uploadLabel]="'Upload'"-->
<!--  [cancelLabel]="'Cancel'"-->
<!--  [mode]="'basic'"-->
<!--  [maxFileSize]="5000000"-->
<!--[showUploadButton]="false"-->
<!--[showCancelButton]="true"-->
<!--[multiple]="false"-->
<!--&gt;</p-fileUpload>-->
<p-fileUpload name="myfile[]" url="url here" [multiple]="false"
              accept="image/*" [maxFileSize]="1000000"
              (uploadHandler)="handleUpload($event)"
              [customUpload]="true" [auto]="true" chooseLabel="Edit"
              chooseIcon=""
>
<!--  <ng-template pTemplate="toolbar">-->
<!--    <div>Upload 3 Files</div>-->
<!--  </ng-template>-->
<!--  <ng-template let-file pTemplate="file">-->
<!--    <div>Custom UI to display a file</div>-->
<!--  </ng-template>-->
  <ng-template pTemplate="content">
    <img *ngIf="imageDataUrl" [src]="imageDataUrl" alt="Uploaded Image" height="100" width="100">
    <img
      *ngIf="!imageDataUrl"
      [src]="logoUrl | async"
      alt="logo"
      class="mr-3 logo-size md:logo-size-lg lg:logo-size-lg"
      onerror="this.onerror=null;this.src='assets/tagabrew_beer_icon.png';"
    />
  </ng-template>
</p-fileUpload>
