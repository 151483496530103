/*
 * Copyright © MEFTEK LLC. All rights reserved.
 * This information is confidential and proprietary to MEFTEK LLC
 * and may not be used, modified, copied or distributed.
 */

import {SelectItem} from "primeng/api";

export const defaultSortField = 'displayName';
export const defaultSortOrder = 1;

export const columnDefs = [
  { field: 'uid', header: 'uid', index: 0, width: '20rem' },
  { field: 'displayName', header: 'Name', index: 1, width: '15rem' },
  { field: 'email', header: 'Email', index: 2, width: '15rem' },
  { field: 'ageGroup', header: 'ageGroup', index: 3, width: '9rem' },
  { field: 'zipCode', header: 'zipCode', index: 4, width: '9rem' },
  { field: 'emailVerified', header: 'emailVerified', index: 5, width: '11rem' },
  { field: 'createdOn', header: 'createdOn', index: 6, width: '11rem' },
  { field: 'lastUsedOn', header: 'lastUsedOn', index: 7, width: '11rem' },
  { field: 'android', header: 'Android', index: 8, width: '8rem' },
  { field: 'iOS', header: 'iOS', index: 9, width: '6rem' },
  { field: 'web', header: 'Web', index: 10, width: '6rem' },
];

export const mobileColumnDefs = [
  { field: 'uid', header: 'uid', index: 0, width: '20rem' },
  { field: 'displayName', header: 'Name', index: 1, width: '15rem' },
  { field: 'email', header: 'Email', index: 2, width: '15rem' },
  { field: 'lastUsedOn', header: 'lastUsedOn', index: 7, width: '11rem' },
];

export const yesNoTFDD: SelectItem[] = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
  { label: '---', value: undefined },
];
