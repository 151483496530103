/*
 * Copyright © MEFTEK LLC. All rights reserved.
 * This information is confidential and proprietary to MEFTEK LLC
 * and may not be used, modified, copied or distributed.
 */

import {Component, OnInit, ViewChild} from '@angular/core';
import {FirebaseService} from "../../services/firebase.service";
import * as userProfileConf from "./user-profile.conf";
import {Table} from "primeng/table";
import {MessageService, SelectItem} from "primeng/api";
import {Router} from "@angular/router";
import {StorageService} from "../../shared/services/storage.service";
import {DeviceDetectorService, DeviceInfo} from "ngx-device-detector";
import {User} from "./user.model";
import {saveSort} from "../../shared/utilities";
import {CsvExportService} from "../../services/csv-export.service";
import {AdminService, AppPermissions, defaultAppPermissions} from "../../services/admin-service";
import {NGXLogger} from "ngx-logger";
import {AuthService} from "../../shared/services/auth.service";

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit  {

  @ViewChild('userStatsTable') userProfileTable: Table;

  private _selectedColumns: any[];
  get selectedColumns(): any[] {
    return this._selectedColumns;
  }
  set selectedColumns(val: any[]) {
    //restore original order
    this._selectedColumns = val.sort((a,b) => a.index - b.index);// [...val];// this.columns.filter(col => val.includes(col));
  }

  private deviceInfo: DeviceInfo;
  public isMobile: boolean;

  users: User[] = [];
  selectedUsers: User[] = [];

  loading: boolean = false;
  loadingBody: boolean = true;
  columns: any[] = userProfileConf.columnDefs;
  mapsColumns: any[] = userProfileConf.columnDefs;

  columnFiltersActive: boolean;
  globalFilterActive: boolean;
  sortActive: boolean;

  yesNoTfDD: SelectItem[] = userProfileConf.yesNoTFDD;

  sortField: string = userProfileConf.defaultSortField;
  sortOrder: number = userProfileConf.defaultSortOrder;
  filters: any = {};

  adminPermissions: AppPermissions = defaultAppPermissions;

  initialized: boolean;

  constructor(
    public readonly authService: AuthService,
    private readonly storageService: StorageService,
    private readonly router: Router,
    private readonly firebaseService: FirebaseService,
    private readonly deviceService: DeviceDetectorService,
    private readonly csvExportService: CsvExportService,
    private readonly adminService: AdminService,
    private readonly logger: NGXLogger,
    private readonly messageService: MessageService,
  ) {
    this.deviceInfo = deviceService.getDeviceInfo()
  }

  ngOnInit(): void {
    this.getAdminPermissions();
    this.getUserProfiles();

    this.isMobile = this.deviceService.isMobile();

    this.selectedColumns = this.isMobile ? userProfileConf.mobileColumnDefs : userProfileConf.columnDefs;

    // Retrieve settings from storage on initialization
    const savedFilters = this.storageService.getItem(StorageService.userStatsFilter);
    const savedSortField = this.storageService.getItem(StorageService.userStatsSortField);
    const savedSortOrder = this.storageService.getItem(StorageService.userStatsSortOrder);

    if (savedSortField) {
      this.sortField = savedSortField;
    }
    if (savedSortOrder) {
      this.sortOrder = savedSortOrder;
    }
    if (savedFilters) {
      this.filters = savedFilters;
    }

    setTimeout(() => {
      this.initialized = true;
    },1000);
  }

  private getUserProfiles() {
    this.firebaseService.getUsers().subscribe({
      next: data => {
        this.users = data;
        this.messageService.add({
          key: 'bc',
          life: 3000,
          severity: 'success',
          summary: 'Success',
          detail: 'User Profiles Successfully Loaded',
        });
      },
      error: err => {
        const errMsg = `User Profiles Load Failed, ${err}`;
        this.logger.error(errMsg);
        this.loading = false;
        this.messageService.add({
          key: 'bc',
          life: 3000,
          severity: 'error',
          summary: 'Error',
          detail: errMsg,
        });
      }
    })
  }

  /**
   * Get admin properties from database
   * @private
   */
  private getAdminPermissions(): void {
    this.adminService.getAppPermissions()
      .then((appPermissions) => {
        if (appPermissions) {
          this.adminPermissions = appPermissions;
          this.logger.info('Admin App Permissions:', appPermissions);
        }
      }).catch((err) => {
      const errMsg = `Error getting admin permissions, edits are disabled, ${err}`;
      this.logger.error(errMsg);
      this.messageService.add({
        key: 'bc',
        life: 3000,
        severity: 'error',
        summary: 'Error',
        detail: errMsg,
      });
    });
  }

  isBooleanColumn(field: string): boolean {
    const columns = [
      'iOS',
      'web',
      'android',
      'emailVerified',
    ];
    return columns.includes(field);
  }

  isDateColumn(field: string): boolean {
    const columns = [
      'campaignEnd',
      'campaignStart',
    ];
    return columns.includes(field);
  }

  isNumericColumn(field: string): boolean {
    const columns = [
      'coordinatesLat',
      'coordinatesLng',
      'id',
      'radius',
      'statisticsClicked',
      'statisticsDisplayed',
    ];
    return columns.includes(field);
  }

  /**
   * Returns true if user can view
   */
  get canView(): boolean {
    return this.adminPermissions.canView?.includes(this.authService.userUid);
  }

  /**
   * Returns true if selectedColumns has changed
   */
  get selectedColumnsChanged(): boolean {
    // TODO: This need to see if they are equal not in size but content
    return this.selectedColumns.length !== (this.isMobile ? userProfileConf.mobileColumnDefs.length : userProfileConf.columnDefs.length);
  }

  /**
   * Returns true if places are filtered
   */
  get isFiltered(): boolean {
    return this.globalFilterActive || this.columnFiltersActive || this.sortActive || this.selectedColumnsChanged;
  }

  /**
   * Globally filter the table by $event
   * @param table The table to filter
   * @param $event The value to filter by (contains)
   */
  globalFilter(table: Table, $event: any): void {
    table.filterGlobal($event.target.value, 'contains');
    this.globalFilterActive = $event.target.value.length > 0;
  }

  /**
   * Update filter when entering filter text for table
   * @param $event The event data for the filter
   */
  handleFilter($event: any) {
    let columnFiltersActive = false;
    (Object.keys($event.filters) as (keyof typeof $event.filters)[]).forEach((key, _index) => {
      if ($event.filters[key].length > 1 || $event.filters[key][0]?.value != null) {
        columnFiltersActive = true;
      }
    });
    this.columnFiltersActive = columnFiltersActive;
  }

  handleClearFilter(table: Table) {
    this.globalFilter(table, { target: { value: '' } });
    table.reset();
    table.sortField = userProfileConf.defaultSortField;
    table.sortOrder = userProfileConf.defaultSortOrder;
    table.sortSingle();
    this.selectedColumns = this.isMobile ? userProfileConf.mobileColumnDefs : userProfileConf.columnDefs;
  }

  handleSort($event: any) {
    this.sortActive = saveSort(
      this.storageService,
      userProfileConf.defaultSortField,
      userProfileConf.defaultSortOrder,
      StorageService.userStatsSortField,
      StorageService.userStatsSortOrder,
      $event
    );
  }

  handleDelete() {
    alert("Delete Not Implemented.")
  }

  handlePlaces() {
    this.router.navigate(['/dashboard']).then();
  }

  handleAds() {
    this.router.navigate(['/ads-list']).then();
  }

  /**
   * Handle the csv export button click
   */
  handleCsvExport() {
    this.userProfileTable.exportCSV();
    // this.csvExportService.exportCsvFile(this.users, this.mapsColumns, 'TagaBrew User Profile List').then();
  }

  /**
   * This method converts and returns a string in PascalCase
   * @param s The string to convert
   */
  pascalCase(s: string): string {
    return s.replace(/(\w)(\w*)/g,
      function(g0,g1, g2){return g1.toUpperCase() + g2.toLowerCase();});
  }

}
