import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  static placesFilter = 'placesFilter';
  static placesSortField = 'placesSortField';
  static placesSortOrder = 'placesSortOrder';

  static userStatsFilter = 'userStatsFilter';
  static userStatsSortField = 'userStatsSortField';
  static userStatsSortOrder = 'userStatsSortOrder';

  static adStatsFilter = 'adStatsFilter';
  static adStatsSortField = 'adStatsSortField';
  static adStatsSortOrder = 'adStatsSortOrder';

  setItem(key: string, value: any): void {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  getItem(key: string): any {
    const item = sessionStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  }

  removeItem(key: string): void {
    sessionStorage.removeItem(key);
  }

  clear(): void {
    sessionStorage.clear();
  }
}
