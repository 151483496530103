/*
 * Copyright © MEFTEK LLC. All rights reserved.
 * This information is confidential and proprietary to MEFTEK LLC
 * and may not be used, modified, copied or distributed.
 */

import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../shared/services/auth.service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
})
export class SignInComponent implements OnInit {
  appVersion: string;
  dbEnv: string;

  constructor(public authService: AuthService) {}

  ngOnInit() {
    this.appVersion = environment.app.version;
    this.dbEnv = environment.app.dbEnv;
  }
}
