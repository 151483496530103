<!--
  ~ Copyright © MEFTEK LLC. All rights reserved.
  ~ This information is confidential and proprietary to MEFTEK LLC
  ~ and may not be used, modified, copied or distributed.
  -->

<p-dialog
  *ngIf="visible" xmlns="http://www.w3.org/1999/html"
  [modal]="true"
  [(visible)]="visible"
  [style]="{width: '75vw', height: '75vh'}"
  [styleClass]="'custom-dialog'"
  (onHide)="visible = false"
  (onShow)="onShow()"
>
  <ng-template pTemplate="header">
    <div class="text-3xl">
      {{ currentAd?.id }}: {{ currentAd?.name }}
    </div>
  </ng-template>
  <ng-template pTemplate="content">
    <div class="p-card" style="display: flex; flex-direction: column; height: 100%;">
      <form #adForm="ngForm" (ngSubmit)="adForm.form.valid && handleSubmit()" novalidate>
        <div class="formgrid grid p-fluid">
          <div class="field col-2 md:col-2 lg:col-2">
            <label [ngClass]="{ required: true }" for="id">ID</label>
            <input
              #id="ngModel"
              (ngModelChange)="onChangeName($event)"
              [(ngModel)]="currentAd.id"
              class="inputfield w-full form-control"
              id="id"
              name="id"
              pInputText
              placeholder="Enter id..."
              required
              type="number"
            />
            <small *ngIf="id.errors?.['required']" class="p-error">ID is required</small>
          </div>
          <div class="field col-4 md:col-4 lg:col-4">
            <label [ngClass]="{ required: true }" for="name">Name</label>
            <input
              #name="ngModel"
              (ngModelChange)="onChangeName($event)"
              [(ngModel)]="currentAd.name"
              class="inputfield w-full form-control"
              id="name"
              name="name"
              pInputText
              placeholder="Enter name..."
              required
              type="text"
            />
            <small *ngIf="name.errors?.['required']" class="p-error">Name is required</small>
          </div>
          <div class="field col-2 md:col-2 lg:col-2">
            <label [ngClass]="{ required: true }" for="radius">Radius (miles)</label>
            <input
              #radius="ngModel"
              (ngModelChange)="onChangeName($event)"
              [(ngModel)]="currentAd.radius"
              class="inputfield w-full form-control"
              id="radius"
              name="radius"
              pInputText
              placeholder="Enter radius..."
              required
              type="number"
            />
            <small *ngIf="radius.errors?.['required']" class="p-error">Radius is required</small>
          </div>

          <div class="field col-4 md:col-4 lg:col-4"></div>

          <div class="field col-2 md:col-2 lg:col-2">
            <label [ngClass]="{ required: true }" for="start">Start</label>
            <input
              #start="ngModel"
              (ngModelChange)="onChangeName($event)"
              [(ngModel)]="currentAd.campaign.start"
              class="inputfield w-full form-control"
              id="start"
              name="start"
              pInputText
              placeholder="Enter start..."
              required
              type="text"
            />
            <small *ngIf="start.errors?.['required']" class="p-error">Start is required</small>
          </div>
          <div class="field col-2 md:col-2 lg:col-2">
            <label [ngClass]="{ required: true }" for="end">End</label>
            <input
              #end="ngModel"
              (ngModelChange)="onChangeName($event)"
              [(ngModel)]="currentAd.campaign.end"
              class="inputfield w-full form-control"
              id="end"
              name="end"
              pInputText
              placeholder="Enter end..."
              required
              type="text"
            />
            <small *ngIf="end.errors?.['required']" class="p-error">End is required</small>
          </div>
          <div class="field col-2 md:col-2 lg:col-2">
            <label [ngClass]="{ required: true }" for="lat">Latitude</label>
            <input
              #lat="ngModel"
              (ngModelChange)="onChangeName($event)"
              [(ngModel)]="currentAd.coordinates.lat"
              class="inputfield w-full form-control"
              id="lat"
              name="lat"
              pInputText
              placeholder="Enter lat..."
              required
              type="number"
            />
            <small *ngIf="lat.errors?.['required']" class="p-error">Latitude is required</small>
          </div>
          <div class="field col-2 md:col-2 lg:col-2">
            <label [ngClass]="{ required: true }" for="lng">Longitude</label>
            <input
              #lng="ngModel"
              (ngModelChange)="onChangeName($event)"
              [(ngModel)]="currentAd.coordinates.lng"
              class="inputfield w-full form-control"
              id="lng"
              name="lng"
              pInputText
              placeholder="Enter lng..."
              required
              type="number"
            />
            <small *ngIf="lng.errors?.['required']" class="p-error">Lng is required</small>
          </div>
          <div class="field col-2 md:col-2 lg:col-2">
            <label [ngClass]="{ required: true }" for="clicked">Clicked</label>
            <input
              #clicked="ngModel"
              (ngModelChange)="onChangeName($event)"
              [(ngModel)]="currentAd.statistics.clicked"
              class="inputfield w-full form-control"
              id="clicked"
              name="clicked"
              pInputText
              placeholder="Enter clicked..."
              required
              type="number"
            />
            <small *ngIf="clicked.errors?.['required']" class="p-error">Clicked is required</small>
          </div>
        </div>
      </form>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="flex justify-content-between flex-wrap">
      <div class="flex align-items-left justify-content-center text-sm">
        <span style="color: red">*</span>&nbsp;<span>Required Field</span>
      </div>
      <div class="flex align-items-right justify-content-center">
        <p-button
          (onClick)="handleCancel()"
          icon="pi pi-times"
          label="Cancel"
          styleClass="p-button-outlined"
        ></p-button>
        <p-button
          (onClick)="adForm.ngSubmit.emit()"
          [disabled]="
            !adForm.form.dirty ||
            adForm.form.pristine ||
            !adForm.form.valid
          "
          icon="pi pi-check"
          label="Submit"
        ></p-button>
<!--        !isDirty ||-->
      </div>
    </div>

  </ng-template>
</p-dialog>
