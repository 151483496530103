/*
 * Copyright © MEFTEK LLC. All rights reserved.
 * This information is confidential and proprietary to MEFTEK LLC
 * and may not be used, modified, copied or distributed.
 */

import {Injectable} from '@angular/core';
import {AngularFireDatabase, AngularFireObject} from '@angular/fire/compat/database';
import {firstValueFrom, Observable, of, Subscriber} from 'rxjs';
import {Place, preparePlace} from '../components/place/place.model';
import {Version} from '../models/version.model';
import {DatePipe} from '@angular/common';
import {NGXLogger} from "ngx-logger";
import {EnvTypeEnum, envTypeIsStage} from "../models/env-type.enum";
import {environment} from "../../environments/environment";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {AngularFireStorage} from "@angular/fire/compat/storage";
import {finalize} from "rxjs/operators";
import {collection, doc, getDoc, getDocs, getFirestore} from "firebase/firestore";
import {initializeApp} from "@angular/fire/app";
import {AngularFireFunctions} from "@angular/fire/compat/functions";

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  private readonly realtimePlacesProd = 'data/places';
  private readonly realtimePlacesStage = 'stage/places';
  private readonly realtimeVersionProd = 'data/version';
  private readonly realtimeVersionStage = 'stage/version';

  private readonly realtimeLogStage = 'logs';

  private readonly storageLogo = 'logo';

  private readonly firestorePlacesProd = 'data/prod/places';
  private readonly firestorePlacesStage = 'data/stage/places';
  private readonly firestoreVersionProd = 'data/prod';
  private readonly firestoreVersionStage = 'data/stage';

  constructor(
    private readonly db: AngularFireDatabase,
    private readonly firestore: AngularFirestore,
    private readonly storage: AngularFireStorage,
    private readonly datePipe: DatePipe,
    private readonly logger: NGXLogger,
    private readonly functions: AngularFireFunctions,
  ) {
  }


  /*** Helper Functions ***/

  /**
   * Returns a new version object based on version and count
   * @param version The base version to create the new version from
   * @param count The count used to create new version with
   */
  public createNewVersion(version: Version, count: number): Version {
    const date: Date = new Date();
    const versionDate: string = this.datePipe.transform(date, 'MMddyy') ?? '000000';
    const versionModel: number = version.model;
    const versionArray = version.number.split('.', 2);
    let major = Number(versionArray[0] ?? '0');
    let minor = Number(versionArray[1] ?? '0');

    if (major < count) {
      major = count;
      minor = 1;
    } else {
      minor += 1;
    }

    const versionNumber: string = `${major}.${minor}`;
    return { date: versionDate, model: versionModel, number: versionNumber };
  }


  /*** Realtime Database Methods ***/


  /**
   * Get the Admin permissions from realtime database
   */
  // public async getAdminPermissions(): Promise<AdminProperties> {
  //   return await firstValueFrom(
  //     this.db.object<AdminProperties>('admin/app-permissions').valueChanges()
  //   ) ?? defaultAdminProperties;
  // }

  /**
   * Get the email address to sending updates from realtime database
   */
  // public async getEmailsAddresses(): Promise<EmailAddresses> {
  //   return await firstValueFrom(
  //     this.db.object<EmailAddresses>('admin/email-addresses').valueChanges()
  //   ) ?? defaultEmailAddresses;
  // }

  /**
   * Get the place objects from PROD realtime database
   */
  public getPlacesProd(state: string): Observable<Place[]> {
    return this.getPlaces(EnvTypeEnum.PROD, state);
  }

  /**
   * Get the place objects from STAGE realtime database
   */
  public getPlacesStage(state?: string): Observable<Place[]> {
    return this.getPlaces(EnvTypeEnum.STAGE, state);
  }

  /**
   * Get the place objects from realtime database
   * @param envType The environment to get from (STAGE or PROD)
   * @param state The state to query or us for all
   */
  private getPlaces(envType: EnvTypeEnum, state?: string): Observable<Place[]> {
    const placesPath = envTypeIsStage(envType)
      ? this.realtimePlacesStage
      : this.realtimePlacesProd;
    if (state != null && state.length > 0 && state.toLowerCase() !== 'usa') {
      return this.db.list<Place>(placesPath, ref => ref.orderByChild('state').equalTo(state)).valueChanges();
    } else {
      return this.db.list<Place>(placesPath).valueChanges();
    }
  }

  /**
   * Sets array of place objects to PROD realtime database
   * @param places The array place objects to set
   * @param placeIdsToSave The placeIds to reset, save
   */
  public setPlacesByIdsProd(places: Place[], placeIdsToSave: number[]): Observable<Place> {
    return this.setPlacesByIds(places, placeIdsToSave, EnvTypeEnum.PROD);
  }

  /**
   * Sets array of place objects to STAGE realtime database
   * @param places The array place objects to set
   * @param placeIdsToSave The placeIds to reset, save
   */
  public setPlacesByIdsStage(places: Place[], placeIdsToSave: number[]): Observable<Place> {
    return this.setPlacesByIds(places, placeIdsToSave, EnvTypeEnum.STAGE);
  }

  /**
   * Sets array of place objects to realtime database
   * @param places The array place objects to set
   * @param placeIdsToSave The placeIds to reset, save
   * @param envType The environment to get from (STAGE or PROD)
   */
  public setPlacesByIds(places: Place[], placeIdsToSave: number[], envType: EnvTypeEnum): Observable<Place> {
    return new Observable((observer) => {
      const placesToSave: Place[] = [];
      placeIdsToSave.forEach((placeId) => {
        const index = places.findIndex((place) => place.id === placeId);
        if (index !== -1) {
          placesToSave.push(places[index]);
        }
      });
      this.savePreparedPlaces(placesToSave, envType, observer);
    });
  }

  /**
   * Sets array of place objects to PROD realtime database
   * @param places The array place objects to set
   * @param placesToSave The place objects to reset, save
   */
  public setPlacesProd(places: Place[], placesToSave: Place[]): Observable<Place> {
    return this.setPlaces(places, placesToSave, EnvTypeEnum.PROD);
  }

  /**
     * Sets array of place objects to STAGE realtime database
     * @param places The array place objects to set
     * @param placesToSave The place objects to reset, save
     */
  public setPlacesStage(places: Place[], placesToSave: Place[]): Observable<Place> {
    return this.setPlaces(places, placesToSave, EnvTypeEnum.STAGE);
  }

  /**
   * Sets array of place objects to realtime database
   * @param places The array place objects to set
   * @param placesToSave The place objects to reset, save
   * @param envType The environment to get from (STAGE or PROD)
   */
  public setPlaces(places: Place[], placesToSave: Place[], envType: EnvTypeEnum): Observable<Place> {
    return new Observable((observer) => {
      this.savePreparedPlaces(placesToSave, envType, observer);
    });
  }

  /**
   * Save the prepared places (db ready places)
   * @param placesToSave
   * @param envType
   * @param observer
   * @private
   */
  private savePreparedPlaces(placesToSave: Place[], envType: EnvTypeEnum, observer: Subscriber<Place>) {
    if (placesToSave.length > 0) {
      placesToSave.forEach((place, index) => {
        const preparedPlace = preparePlace(place, envType);
        this.setPlaceToBothDbs(preparedPlace, envType)
          .then(() => {
            observer.next(preparedPlace);
            if (index === placesToSave.length - 1) {
              observer.complete();
            }
          })
          .catch((err) => {
            this.logger.error(err);
            observer.error(err);
          });
      });
    } else {
      observer.next();
      observer.complete();
    }
  }

  /**
   * Get places by placeIds from PROD realtime database
   * @param placeIds The array of placeIds to get
   */
  public getPlacesByIdsProd(placeIds: number[]): Observable<Place[]> {
    return this.getPlacesByIds(placeIds, EnvTypeEnum.PROD);
  }

  /**
   * Get places by placeIds from STAGE realtime database
   * @param placeIds The array of placeIds to get
   */
  public getPlacesByIdsStage(placeIds: number[]): Observable<Place[]> {
    return this.getPlacesByIds(placeIds, EnvTypeEnum.STAGE);
  }

  /**
   * Get places by placeIds from realtime database
   * @param placeIds The array of placeIds to get
   * @param envType The environment to get from (STAGE or PROD)
   */
  private getPlacesByIds(placeIds: number[], envType: EnvTypeEnum): Observable<Place[]> {
    return new Observable((observer) => {
      const places: Place[] = [];
      const total = placeIds.length - 1;
      if (placeIds.length > 0) {
        placeIds.forEach((placeId, index) => {
          this.getPlaceById(placeId, envType)
            .then((place) => {
              if (place) {
                places.push(place);
              }
              if (index >= total) {
                observer.next(places);
                observer.complete();
              }
            })
            .catch((err) => {
              this.logger.error(err);
              observer.error(err);
            });
        });
      } else {
        observer.next(places);
        observer.complete();
      }
    });
  }

  /**
   * Get a place by its id from PROD realtime database
   * @param placeId The id of the place to get
   */
  getPlaceByIdProd(placeId: number): Promise<Place | null> {
    return this.getPlaceById(placeId, EnvTypeEnum.PROD);
  }

  /**
   * Get a place by its id from STAGE realtime database
   * @param placeId The id of the place to get
   */
  getPlaceByIdStage(placeId: number): Promise<Place | null> {
    return this.getPlaceById(placeId, EnvTypeEnum.STAGE);
  }

  /**
   * Get a place by its id from realtime database
   * @param placeId The id of the place to get
   * @param envType The environment to get from (STAGE or PROD)
   */
  private getPlaceById(placeId: number, envType: EnvTypeEnum): Promise<Place | null> {
    const placesPath = envTypeIsStage(envType)
      ? this.realtimePlacesStage
      : this.realtimePlacesProd;
    return firstValueFrom(
      this.db.object<Place>(`${placesPath}/${placeId}`).valueChanges()
    );
  }

  /**
   * Sets the place object to PROD realtime database
   * @param place The place object to set
   */
  public setPlaceProd(place: Place): Promise<void> {
    return this.setPlaceToBothDbs(place, EnvTypeEnum.PROD);
  }

  /**
   * Sets the place object STAGE to realtime database
   * @param place The place object to set
   */
  public setPlaceStage(place: Place): Promise<void> {
    return this.setPlaceToBothDbs(place, EnvTypeEnum.STAGE);
  }

  /**
   * Sets the place object to realtime database
   * @param place The place object to set
   * @param envType The environment to get from (STAGE or PROD)
   */
  // private setPlace(place: Place, envType: EnvTypeEnum): Promise<void> {
  //   const placesPath = FirebaseService.isStage(envType)
  //     ? this.realtimePlacesStage
  //     : this.realtimePlacesProd;
  //   return this.db.object<Place>(`${placesPath}/${place.id}`).set(place);
  // }

  async setPlaceToBothDbs(place: Place, envType: EnvTypeEnum) {
    const realtimeDbPath: string = `${envTypeIsStage(envType) ? this.realtimePlacesStage : this.realtimePlacesProd}/${place.id}`;
    const firestorePath: string = `${envTypeIsStage(envType) ? this.firestorePlacesStage : this.firestorePlacesProd}/${this.formatId(place.id, 4)}`;
    // const preparedPlace = preparePlace(place, envType, true);
    try {
      await this.db.object(realtimeDbPath).set(place);  //preparedPlace
      await this.firestore.doc(firestorePath).set(place); //preparedPlace
    } catch (error) {
      throw new Error(`Failed to add place to one or both databases: ${error}`);
    }
  }

  /**
   * Deletes a place object from PROD realtime database
   * @param placeId The id of the place to delete
   */
  public deletePlaceByIdProd(placeId: number): Promise<void> {
    return this.deletePlaceByIdFromBothDbs(placeId, EnvTypeEnum.PROD);
  }

  /**
   * Deletes a place object from STAGE realtime database
   * @param placeId The id of the place to delete
   */
  public deletePlaceByIdStage(placeId: number): Promise<void> {
    return this.deletePlaceByIdFromBothDbs(placeId, EnvTypeEnum.STAGE);
  }

  /**
   * Deletes a place of object from realtime database
   * @param placeId The id of the place to delete
   * @param envType The environment to get from (STAGE or PROD)
   */
//   private deletePlaceById(placeId: number, envType: EnvTypeEnum): Promise<void> {
//     const placesPath = FirebaseService.isStage(envType)
//       ? this.realtimePlacesStage
//       : this.realtimePlacesProd;
// xx    this.firestore.collection('places').doc(envType === EnvTypeEnum.STAGE ? 'stage' : 'prod')
//       .collection('data').doc(this.formatId(placeId, 4)).delete().then();
//     return this.db.object<Place>(`${placesPath}/${placeId}`).remove();
//   }

  private async deletePlaceByIdFromBothDbs(placeId: number, envType: EnvTypeEnum): Promise<void> {
    const realtimeDbPath: string = `${envTypeIsStage(envType) ? this.realtimePlacesStage : this.realtimePlacesProd}/${placeId}`;
    const firestorePath: string = `${envTypeIsStage(envType) ? this.firestorePlacesStage : this.firestorePlacesProd}/${this.formatId(placeId, 4)}`;
    try {
      await this.db.object<Place>(realtimeDbPath).remove();
      await this.firestore.doc(firestorePath).delete();
    } catch (error) {
      throw new Error(`Failed to delete place from one or both databases: ${error}`);
    }
  }

  /**
   * Deletes an array of place objects from STAGE realtime database
   * @param placeIds The id of the place to delete
   */
  public deletePlacesByIdStage(placeIds: number[]): Observable<void> {
    return this.deletePlacesById(placeIds, EnvTypeEnum.STAGE);
  }

  /**
   * Deletes an array of place objects from realtime database
   * @param placeIds The id of the place to delete
   * @param envType The environment to get from (STAGE or PROD)
   */
  private deletePlacesById(placeIds: number[], envType: EnvTypeEnum): Observable<void> {
    return new Observable((observer) => {
      if (placeIds.length > 0) {
        const total = placeIds.length - 1;
        placeIds.forEach((placeId, index) => {
          this.deletePlaceByIdFromBothDbs(placeId, envType)
            .then(() => {
              if (index >= total) {
                observer.next();
                observer.complete();
              }
            })
            .catch((err) => {
              this.logger.error(err);
              observer.error(err);
            });
        });
      } else {
        observer.next();
        observer.complete();
      }
    });
  }

  // /**
  //  * Get the version from PROD realtime database
  //  */
  // public getVersionProd(): AngularFireObject<Version> {
  //   return this.getVersion(EnvTypeEnum.PROD);
  // }
  //
  // /**
  //  * Get the version from STAGE realtime database
  //  */
  // public getVersionStage(): AngularFireObject<Version> {
  //   return this.getVersion(EnvTypeEnum.STAGE);
  // }

  /**
   * Get the version realtime database
   * @param envType The environment to get from (STAGE or PROD)
   */
  public getVersion(envType: EnvTypeEnum): AngularFireObject<Version> {
    const versionPath = `${envTypeIsStage(envType) ? this.realtimeVersionStage : this.realtimeVersionProd}`;
    return this.db.object<Version>(versionPath);
  }

  /**
   * Sets the version in PROD realtime database
   * @param version The version object to set
   */
  public setVersionProd(version: Version): Promise<void> {
    return this.setVersionToBothDbs(version, EnvTypeEnum.PROD);
  }

  /**
   * Sets the version in STAGE realtime database
   * @param version The version object to set
   */
  public setVersionStage(version: Version): Promise<void> {
    return this.setVersionToBothDbs(version, EnvTypeEnum.STAGE);
  }

  /**
   * Sets the version in realtime database
   * @param version he version object to set
   * @param envType The environment to get from (STAGE or PROD)
   */
  private XXXsetVersion(version: Version, envType: EnvTypeEnum): Promise<void> {
    const versionPath = `${envTypeIsStage(envType) ? this.realtimeVersionStage : this.realtimeVersionProd}`;
    return this.db.object<Version>(versionPath).set(version);
  }

  private async setVersionToBothDbs(version: Version, envType: EnvTypeEnum): Promise<void> {
    const realtimeDbPath: string = `${envTypeIsStage(envType) ? this.realtimeVersionStage : this.realtimeVersionProd}`;
    const firestorePath: string = `${envTypeIsStage(envType) ? this.firestoreVersionStage : this.firestoreVersionProd}`;
    try {
      await this.db.object(realtimeDbPath).set(version);
      await this.firestore.doc(firestorePath).set(version);
    } catch (error) {
      throw new Error(`Failed to add version to one or both databases: ${error}`);
    }

    const versionPath = `${envTypeIsStage(envType) ? this.realtimeVersionStage : this.realtimeVersionProd}`;
    return this.db.object<Version>(versionPath).set(version);
  }

  /**
   * Resets the version in STAGE realtime database
   * @private
   */
  public resetVersionStage(): Observable<Version> {
    return new Observable((observer) => {
      // TODO: 9/23/2024 - change to valueChanges()
      // if (verison) else observer.next(null); observer.complete();
      firstValueFrom(this.getVersion(EnvTypeEnum.PROD).snapshotChanges())
        .then(data => {
          this.logger.debug('WE ARE HERE YEAH!');
          const version: Version = data.payload.val() as Version;
          this.setVersionStage(version)
            .then(() => {
              observer.next(version);
              observer.complete();
            })
            .catch(err => {
              this.logger.error(err);
              observer.error(err);
            });
        })
        .catch(err => {
          this.logger.error(err);
          observer.error(err);
        }
      );
    });
  }

  /**
   * This method write the log for the change place
   * @param placeId The id of the place to log
   * @param logData The log data to write to the log
   * @param version The version of the database
   */
  stageLog(placeId: number, logData: any, version: Version): void {
    const logId = `${version.number}:${version.date}-${new Date().toLocaleString()}`.replace(/[.$#\[\]]/g,'');
    const logPath = `${this.realtimeLogStage}`;
    this.db.object<string>(`${logPath}/${logId}-${placeId}`).set(logData).then(/* Do nothing */);
  }


  /*** Firebase Firestore Methods ***/


  /**
   * Publish to log to the database
   * @param version The version of the log
   * @param changesPublished Array of place updated
   * @param toEmails Emails to send to
   */
  publishLog(version: Version, changesPublished: Place[], toEmails: string): void {
    const logId = `${version.number}-${version.date}-${new Date().toLocaleString()}`.replace(/[.$#\[\]]/g,'');
    const logPath = `${this.realtimeLogStage}`;
    // this.db.object<Version>(`${logPath}/${logId}-a-published`).set(version).then(/* Do nothing */);
    const logData = {description: 'TagaBrew App Data Published', version, changesPublished};
    this.db.object<any>(`${logPath}/${logId}-published`).set(logData).then(/* Do nothing */);
    const subject = `TagaBrew App Data Published (${environment.app.version}${environment.app.dbEnv}) (DB:${version.number}/${version.date})`
    this.sendEmail(logData, toEmails, subject, false);
  }

  /**
   * Email the log
   * @param logObject The logObject in Json to email
   * @param mailTo Who to email to (optional, default: meftek@gmail.com)
   * @param subject The subject of the email (optional, default: TagaVrew Database update)
   * @param staged true is this is staged data
   */
  sendEmail(logObject: {}, mailTo: string, subject: string, staged: boolean) {
    let text = `The following changes have been ${staged ? 'STAGED' : 'PUBLISHED'}:\n\n`;
    text += JSON.stringify(logObject, null, 2);

    this.firestore.collection("mail").add({
      to: mailTo,
      message: {
       subject: `${subject} - ${new Date().toLocaleString()}`,
       text
      }
    })
      .then(() => {
        this.logger.info("Document successfully written!");
      })
      .catch((error) => {
        this.logger.error("Error writing document: ", error);
      });
  }

  async getUserProfile(uid: string) {
    const app = initializeApp(environment.firebase);
    const db = getFirestore(app);

    const userRef = doc(db, 'users', uid);
    try {
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        this.displayUser(userData);
      } else {
        console.log('No such document!');
      }
    } catch (error) {
      console.log('Error getting document: ', error);
    }
  }

  async getAllUsers() {
    const app = initializeApp(environment.firebase);
    const db = getFirestore(app);

    // Reference to the 'users' collection
    const usersCollectionRef = collection(db, 'users');

    try {
      // Get all documents in the 'users' collection
      const querySnapshot = await getDocs(usersCollectionRef);

      if (!querySnapshot.empty) {
        // Process each document
        querySnapshot.forEach((doc) => {
          console.log(`Document ID: ${doc.id}`);
          console.log('Data:', doc.data());
          // You can call a function to display data or process it further
          this.displayUser(doc.data());
        });
      } else {
        console.log("No users found.");
      }
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  }

  displayUser(user: any) {
    // Display user information
    console.log(`Display Name: ${user.displayName}`);
    console.log(`iOS: ${user.iOS}`);
    console.log(`Email: ${user.email}`);
    console.log(`Last Updated On: ${user.lastUpdatedOn}`);
    console.log(`Age Group: ${user.ageGroup}`);
    console.log(`Zip Code: ${user.zipCode}`);
    console.log(`Email Verified: ${user.emailVerified}`);
    console.log(`Created On: ${user.createdOn}`);
    console.log(`UID: ${user.uid}`);
  }

  getAds(): Observable<any[]> {
    return this.firestore.collection('ads').valueChanges();
  }

  getUsers(): Observable<any[]> {
    return this.firestore.collection('users').valueChanges();
  }

  private formatId(id: number, length: number): string {
    return String(id).padStart(length, '0');
  }

  addMultiplePlaces(places: Place[], envType: EnvTypeEnum): Promise<void[]> {
    const promises = places.map((place, index) => {
      return this.addPlace(place, envType);
    });

    return Promise.all(promises);
  }

  addPlace(place: Place, envType: EnvTypeEnum): Promise<void> {
    // const timestamp = new Date().toISOString();
    const preparedPlace = preparePlace(place, envType);
    const formattedId = this.formatId(preparedPlace.id, 4);
    const firestorePath = `${envTypeIsStage(envType) ? this.firestorePlacesStage : this.firestorePlacesProd}/${formattedId}`;
    return this.firestore.doc(firestorePath).set(preparedPlace).then(() => {
        console.log(`writing: ${firestorePath}`);
        return; // Explicitly returning void
      })
      .catch(error => {
        throw  new Error(error.toLocaleString()); // Rethrow error to handle it in the component if needed
    });
  }

  addVersion(version: Version, envType: EnvTypeEnum) {
    const firestoreVersionPath = `${envTypeIsStage(envType) ? this.firestoreVersionStage : this.firestoreVersionProd}`;
    return this.firestore.doc(firestoreVersionPath).set(version);
  }


  /*** Firebase Storage Methods ***/


  /**
   * Get the logo for place by url
   * @param url The URL of the logo in Firebase Storage
   */
  getLogoByUrl(url?: string): Observable<any> {
    if (url != null && url.length > 0) {
      const logoRef = this.storage.ref(url);
      return logoRef.getDownloadURL();
    }
    return of(null);
  }

  /**
   * Get the logo for place by id
   * @param id of place
   */
  getLogoById(id: number): Observable<any> {
    const idStr = String(id).padStart(4, '0');
    const logoRef = this.storage.ref(`${this.storageLogo}/logo${idStr}.png`);
    return logoRef.getDownloadURL();
  }

  /**
   * Put the logo for place by id
   * @param id of place
   * @param data for the logo
   */
  putLogoById(id: number, data: string) {
    id = 0;
    const metadata = {
      contentType: 'image/jpeg',
    };

    const idStr = String(id).padStart(4, '0');
    const logoRef = this.storage.ref(`${this.storageLogo}/logo${idStr}.jpg`);
    logoRef.put(data, metadata).then(snapshot => {
      console.log(snapshot);
    }).catch(error => {
      console.log(error);
    });
    // logoRef.put(data, metadata).then(snapshot => {
    //   console.log(snapshot);
    // }).catch(error => {
    //   console.log(error);
    // });
  }

  /**
   * Upload a file to storage
   * @param file to upload to storage
   */
  uploadToStorage(file: File) {
    if (file) {
      const filePath = `/images/${file.name}`;
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, file);

      task.snapshotChanges().pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe((url: any) => {
            console.log('File available at', url);
            // You can do something with the URL here, e.g., save it to a database
          });
        })
      ).subscribe();
    }
  }

  uploadLogoToStorage(filename: string, data: any) {
    const path = filename;
    const ref = this.storage.ref(path);
    ref.putString(data, 'data_url').then();
  }


  /*** Firebase Function Methods ***/


  // private apiGetAuthUsersUrl = 'https://us-central1-tagabrew-debug.cloudfunctions.net/listUsers'; // Use your Firebase Function URL

  checkUserDisabled(uid: string) {
    const callable = this.functions.httpsCallable('checkUserDisabled');
    return callable({ uid: uid }).toPromise();
  }

  // getAuthUsers(): Observable<any[]> {
  //   return this.http.get<any[]>(this.apiGetAuthUsersUrl);
  // }
}
