<div [ngClass]="{'dark-mode' : dark, 'light-mode' : !dark}" class="p-2 m-0">
  <h1>TagaBrew Pro App FAQ</h1>
  <h2>What is TagaBrew</h2>
  <p class="pl-2">TagaBrew is a copper memory tag you collect as you brewery hop.</p>
  <p class="pl-2">You can go to a participating brewery and purchase a 4in. chain and tag, normally $2.00 or a 27in.
    chain, tag and a charm, normally $5.00 - $6.00.</p>
  <p class="pl-2">While your there, you may as well enjoy a beer and some good company. You will find TagaBrewers
    everywhere.</p>
  <h2>List View</h2>
  <p class="pl-2">List View shows all TagaBrew breweries as a scrollable list.</p>
  <p class="pl-2">You can sort them by Name (default), State, City or Chronological (order added to TagaBrew).</p>
  <p class="pl-2">You can filter the list to show Collected, Not Collected and Soon.</p>
  <p class="pl-2">If you have marked the tag as collected for the brewery, that row will be displayed with a highlighted
    background.
    A small grey / blue circle icon is displayed to the right, indicating the brewery has a charm to collect. This icon
    will be a grey if the charm has not been collect and blue if it has been collected.</p>
  <p class="pl-2">If the brewery is coming soon (does not have the tags yet) "Brew (SOON)" in <span
    style="color: #F4B400;"><strong>YELLOW</strong></span> will be displayed below the name and address.</p>
  <p class="pl-2">Retired tags will be display "Brew (RETIRED)" in <span
    style="color: grey;"><strong>GRAY</strong></span> will be displayed below the name and address..</p>
  <p class="pl-2">Below the logo on the left your personal rating (0-5 stars) will be displayed if you've rated this
    Brewery.</p>
  <p class="pl-2">The title of this view displays the number of TagaBrew tags you have collected and the total number of
    TagaBrew breweries.</p>
  <p class="pl-2">When you tap a TagaBrew brewery, you will be taken to the Details View for that brewery.</p>
  <h2>Map View</h2>
  <p class="pl-2">Map View shows the TagaBrew breweries on a zoomable map.</p>
  <p class="pl-2">If you allow TagaBrew App to see your location this view will show the TagaBrew breweries near you,
    displaying your current location as a small blue dot.</p>
  <p class="pl-2">You can tap on any marker on the map and then long press the marker bubble to display details for that
    brewery.</p>
  <p class="pl-2">You can tap on the crosshair in the upper right to zoom to your current location if you have moved
    since the last update.</p>
  <p class="pl-2">There is an option of displaying the map as Standard, Satellite or Hybrid views.</p>
  <p class="pl-2">All markers are displayed in <strong><span style="color: green;">GREEN</span></strong>, <strong><span
    style="color: blue;">BLUE</span></strong>, <strong><span style="color: mediumpurple;">PURPLE</span></strong>,
    <strong><span style="color: #F4B400;">YELLOW</span></strong> or <strong><span
      style="color: black; background: darkgrey">BLACK</span> / <span style="color: gray">GRAY</span></strong>
    (Collected, Not Collected, Cheer, Soon or Retired (Not Collected/Collected) respectively).</p>
  <h2>Detail View</h2>
  <p class="pl-2">Here you see the details of the TagaBrew brewery and where you can mark a tag collected, as well as
    the special charm (if the brewery has one)</p>
  <p class="pl-2">You can click on the address to copy it to the clipboard and paste it into another app (e.g.
    Waze).</p>
  <p class="pl-2">If there is a phone number, you can click on it to open the phone app to call.</p>
  <p class="pl-2">You can also select a personal rating (0-5 stars), enter personal notes and click the Directions
    button to get directions. We support Apple Maps, Google Maps and Waze on all platforms.</p>
  <h2>Settings</h2>
  <h3>General</h3>
  <h4>Startup View</h4>
  <p class="pl-2">Choose the Startup View. The application will start with the view selected. List or Map</p>
  <h4>Sort</h4>
  <p class="pl-2">Choose the sort method. The sort can be by Name (default), State, City or Chronological (order added
    to TagaBrew).</p>
  <h4>State Filter</h4>
  <p class="pl-2">This allows you to see only a specific state or all states</p>
  <h4>Display</h4>
  <p class="pl-2">This allows you to show All, All (Collected), All (Not Collected), Brew (All), Brew (Collected), Brew
    (Not Collected), Cheer (All), Cheer (Collected), Cheer (Not Collected), Soon (All), Soon (Brew), Soon (Cheer) or
    Recycled on the List and Map screens.</p>
  <h4>Show Retired</h4>
  <p class="pl-2">Toggle that determines if the Retired TagaBrew breweries are displayed. This is how you can tag a
    TagaBrew brewery that has already been retired from the trail.</p>
  <p class="pl-2"><br/>Note: Tagged Retired TagaBrew breweries will always display on the List and Map tabs. However,
    filters can override this.</p>
  <h4>Map Tab Always On</h4>
  <p class="pl-2">When this option in on the display will stay on when the Map tab is displayed and the device is
    plugged in.</p>
  <h3>Info &amp; Feedback</h3>
  <h4>Feedback</h4>
  <p class="pl-2">Send app related feedback via email</p>
  <h4>FAQ</h4>
  <p class="pl-2">Displays this document</p>
  <h4>TagaBrew.com</h4>
  <p class="pl-2">Launch a web browser to display the TagaBrew web site</p>
  <h4>Backup</h4>
  <p class="pl-2">Click this to send yourself and email with a backup of your TagaBrew data. The email will include
    instructions to restore this data</p>
  <p><br/><em>Copyright &copy; 2017-2023 Revised: August 24, 2023</em></p>
</div>
